import { combine } from 'effector';
import { v4 } from 'uuid';

import { getState } from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import { atom } from '@kuna-pay/utils/misc';
import { GenericFeedback } from '@kuna-pay/core/shared/feedback';

import { $$pushNotifications } from '@kuna-pay/merchant/entities/push-notification';
import { $$session } from '@kuna-pay/merchant/entities/session';
import { $$logout } from '@kuna-pay/merchant/features/auth/logout';
import { publicCollectGenericFeedback } from '@kuna-pay/merchant/shared/api/generated/Feedback/request/publicCollectGenericFeedback';
import { publicGetPresignUrlsForFeedbackAttachments } from '@kuna-pay/merchant/shared/api/generated/Feedback/request/publicGetPresignUrlsForFeedbackAttachments';

const $$headerMenu = atom(() => {
  const $isSystemLogged = combine(
    $$session.$$jwt.$accessTokenJwt,
    (data) => !!data
  );
  const $isSystemLoggedPending = $$session.$$meQuery.$pending;
  const $isCompanyLogged = combine(
    $$session.$$merchantQuery.$data,
    (data) => !!data
  );
  const $isCompanyLoggedPending = $$session.$$merchantQuery.$pending;

  const $$feedback = GenericFeedback.factory.createModel({
    sendFeedbackFx: async (values) => {
      const userEmail = await getState($$session.$$jwt.$$user.$email);
      const companyId = await getState($$session.$$jwt.$$company.$id);

      values.feedback = (values.feedback ?? '').trim();
      const { feedback, attachments: files } = values;

      const attachments: string[] = [];

      if (files.length) {
        const { urls } = await publicGetPresignUrlsForFeedbackAttachments({
          urls: true,
        })({
          feedbackId: v4(),

          previews: files.map((file) => ({
            filename: file.name,
            contentType: file.type,
          })),
        });

        invariant.error(
          urls.length === files.length,
          'Presign urls count should be equal to files count'
        );

        await Promise.all(
          urls.map(async (url, index) => {
            const attachment = files[index];

            await fetch(url, {
              method: 'PUT',
              body: attachment,
              headers: {
                'Content-Type': attachment.type,
                'Content-Length': attachment.size.toString(),
              },
            });
          })
        );

        attachments.push(
          ...urls.map((url) => {
            const [urlBeforeQueryParams] = url.split('?');

            return urlBeforeQueryParams;
          })
        );
      }

      await publicCollectGenericFeedback({ success: true })({
        feedback: feedback,
        attachments,
        meta: {
          userEmail,
          companyId,
        },
      });
    },
  });

  return {
    $isSystemLogged,
    $isSystemLoggedPending,

    $isCompanyLogged,
    $isCompanyLoggedPending,

    $$feedback,
    $$pushNotifications,
    $$userMenu: {
      $$logout: $$logout,
    },
  };
});

export { $$headerMenu };
