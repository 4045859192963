import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowRightIcon } from '@kuna-pay/ui/icons';
import { ExternalLink, NavLink } from '@kuna-pay/ui/router';
import { Button } from '@kuna-pay/ui/ui/button';

import { PushNotificationTemplate } from '@kuna-pay/merchant/entities/push-notification';
import { FreshDeskSDK } from '@kuna-pay/merchant/features/contact-support/fresh-desk';
import type { NotificationOutput } from '@kuna-pay/merchant/generated/graphql';
import { routes } from '@kuna-pay/merchant/shared/router';

type NotificationActionResult =
  | {
      type: 'button';
      action: 'contact-support';
    }
  | {
      type: 'link';
      isExternal: boolean;
      href: string;
      text: string;
    }
  | {
      type: 'none';
    };

const useNotificationAction = (
  notification: NotificationOutput
): NotificationActionResult => {
  const { t } = useTranslation();
  const template = notification.template as PushNotificationTemplate;

  switch (template) {
    case PushNotificationTemplate.SuccessfulLogin: {
      return { type: 'none' };
    }

    case PushNotificationTemplate.IdentificationNeedHelp: {
      return {
        type: 'button',
        action: 'contact-support',
      };
    }

    case PushNotificationTemplate.IdentificationNeedToComplete: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.auth.verification.start
          .withQuery({
            from: 'Notifications',
          })
          .toNavigate(),
        text: t('entities.push-notification.action.verify-my-account'),
      };
    }

    case PushNotificationTemplate.IdentificationDetailsAboutProduct: {
      return {
        type: 'button',
        action: 'contact-support',
      };
    }

    case PushNotificationTemplate.IdentificationRejected: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.auth.verification.start
          .withQuery({
            from: 'Notifications',
          })
          .toNavigate(),
        text: t('entities.push-notification.action.verify-my-account'),
      };
    }

    case PushNotificationTemplate.IdentificationAccepted: {
      return {
        type: 'link',
        isExternal: true,
        href: 'https://docs.kunapay.io/docs/invoices',
        text: t('entities.push-notification.action.learn-more'),
      };
    }

    // TODO
    case PushNotificationTemplate.InvoiceReminderWithHelp: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.tutorials.list
          .withQuery({
            from: 'Notifications',
          })
          .toNavigate(),
        text: t('entities.push-notification.action.watch-tutorial'),
      };
    }

    case PushNotificationTemplate.InvoiceDepositProcessed: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.invoices.details
          .clone({
            id: notification.payload.invoiceId ?? notification.payload.id,
          })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.view-invoice'),
      };
    }

    case PushNotificationTemplate.InvoiceUnPaid: {
      return {
        type: 'link',
        isExternal: true,
        href: 'https://docs.kunapay.io/docs/accept-payments',
        text: t('entities.push-notification.action.read-tutorial'),
      };
    }

    case PushNotificationTemplate.InvoiceDepositSuspended: {
      return {
        type: 'button',
        action: 'contact-support',
      };
    }

    case PushNotificationTemplate.InvoiceDepositCheckSuccessful: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.invoices.details
          .clone({
            id: notification.payload.invoiceId ?? notification.payload.id,
          })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),

        text: t('entities.push-notification.action.view-invoice'),
      };
    }

    case PushNotificationTemplate.InvoiceDepositArrested: {
      return {
        type: 'button',
        action: 'contact-support',
      };
    }

    //
    case PushNotificationTemplate.DepositProcessed: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.transactions.details
          .clone({
            id: notification.payload.transactionId ?? notification.payload.id,
          })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),

        text: t('entities.push-notification.action.view-details'),
      };
    }

    case PushNotificationTemplate.CompanyExceededDepositLimits: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.merchant.create
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.create-business-account'),
      };
    }

    case PushNotificationTemplate.AutoConvertProcessed: {
      return {
        type: 'link',
        href: routes.invoices.details
          .clone({ id: notification.payload.invoiceId })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.view-invoice'),
        isExternal: false,
      };
    }

    //
    case PushNotificationTemplate.ReferralRewardProcessed: {
      return {
        type: 'link',
        href: routes.transactions.details
          .clone({ id: notification.payload.id })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.view-details'),
        isExternal: false,
      };
    }

    case PushNotificationTemplate.ReferralReminderEndDiscount: {
      return {
        type: 'link',
        href: routes.companyFees.root
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.view-fees'),
        isExternal: false,
      };
    }

    //
    case PushNotificationTemplate.WithdrawalProcessed: {
      return {
        type: 'link',
        href: routes.transactions.details
          .clone({
            id: notification.payload.transactionId ?? notification.payload.id,
          })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.view-details'),
        isExternal: false,
      };
    }

    //
    case PushNotificationTemplate.PayoutProcessed: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.payouts.details
          .clone({ id: notification.payload.id })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.view-payout'),
      };
    }

    case PushNotificationTemplate.PayoutFailed: {
      return {
        type: 'link',
        isExternal: false,
        href: routes.payouts.details
          .clone({ id: notification.payload.id })
          .withQuery({ from: 'Notifications' })
          .toNavigate(),
        text: t('entities.push-notification.action.view-payout'),
      };
    }

    default: {
      return { type: 'none' };
    }
  }
};

type NotificationActionProps = {
  className?: string;
  notification: NotificationOutput;
};

const NotificationAction = memo(
  ({ notification, className }: NotificationActionProps) => {
    const { t } = useTranslation();
    const action = useNotificationAction(notification);

    switch (action.type) {
      case 'button': {
        if (action.action === 'contact-support') {
          return (
            <Button
              className={className}
              variant='text'
              color='primary'
              size='lg'
              end={<ArrowRightIcon />}
              disablePaddingTop
              disablePaddingBottom
              onClick={() => FreshDeskSDK.openTicketForm()}
            >
              {t('entities.push-notification.action.contact-support')}
            </Button>
          );
        }

        return null;
      }

      case 'link': {
        if (action.isExternal) {
          return (
            <Button.Root
              className={className}
              variant='text'
              color='primary'
              size='lg'
              asChild
            >
              <ExternalLink href={action.href} target='_blank'>
                <Button.Text size='lg'>{action.text}</Button.Text>

                <ArrowRightIcon />
              </ExternalLink>
            </Button.Root>
          );
        }

        return (
          <Button.Root
            className={className}
            variant='text'
            color='primary'
            size='lg'
            asChild
          >
            <NavLink to={action.href}>
              <Button.Text size='lg'>{action.text}</Button.Text>

              <ArrowRightIcon />
            </NavLink>
          </Button.Root>
        );
      }

      case 'none': {
        return null;
      }

      default: {
        return null;
      }
    }
  }
);

export { NotificationAction, useNotificationAction };
