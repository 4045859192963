import type { FreshDeskValidLanguages } from '@kuna-pay/merchant/features/contact-support/fresh-desk/fresh-desk.i18n';

/**
 * @see https://developers.freshdesk.com/widget-api
 */
class FreshDeskSDK {
  public static hide = () => {
    this.call('hide');
  };

  public static hideLauncher = () => {
    this.call('hide', 'launcher');
  };

  public static show = () => {
    this.call('show');
  };

  public static showLauncher = () => {
    this.call('show', 'launcher');
  };

  public static open = () => {
    this.call('open');
  };

  public static close = () => {
    this.call('close');
  };

  public static destroy = () => {
    this.call('destroy');
  };

  public static boot = () => {
    this.call('boot');
  };

  public static openTicketForm = (args?: { formId: number }) => {
    this.call('open', 'ticketForm', args);
  };

  public static identify = (
    payload: { email: string; name?: string },
    args?: { formId: number }
  ) => {
    this.call('identify', 'ticketForm', payload, args);
  };

  public static prefillTicketForm = (
    payload: {
      subject?: string;
      description?: string;
      priority?: number;
      status?: number;
      group_id?: number;
      product_id?: number;
      type?: 'Question';
      custom_fields?: Record<string, string | number | boolean>;
    },
    args?: { formId: number }
  ) => {
    this.call('prefill', 'ticketForm', payload, args);
  };

  public static disableFieldInTicketForm = (
    payload: (
      | 'subject'
      | 'description'
      | 'priority'
      | 'status'
      | 'group_id'
      | 'product_id'
      | 'type'
      | `custom_fields.cf_${string}`
    )[],
    args?: { formId: number }
  ) => {
    this.call('disable', ' ticketForm', payload, args);
  };

  public static hideFieldInTicketForm = (
    payload: (
      | 'subject'
      | 'description'
      | 'priority'
      | 'status'
      | 'group_id'
      | 'product_id'
      | 'type'
      | `custom_fields.cf_${string}`
    )[],
    args?: { formId: number }
  ) => {
    this.call('hide', 'ticketForm', payload, args);
  };

  public static hideChoicesInTicketForm = (
    payload: {
      priority?: number[];
      status?: number[];
      group_id?: number[];
      type?: 'Question'[];
      custom_fields?: Record<string, (string | number | boolean)[]>;
    },
    args?: { formId: number }
  ) => {
    this.call('hideChoices', 'ticketForm', payload, args);
  };

  public static clearTicketForm = (args?: { formId: number }) => {
    this.call('clear', 'ticketForm', args);
  };

  public static openSolutionArticle = (payload: { id: number }) => {
    this.call('open', 'article', payload);
  };

  public static authenticate = ({
    token,
    onRefresh,
  }: {
    token: string;

    onRefresh: () => Promise<{ token: string }>;
  }) => {
    const callback = () => {
      onRefresh().then(({ token }) => {
        this.call('authenticate', { token });
      });
    };

    this.call('authenticate', { token, callback });
  };

  public static logout = () => {
    this.call('logout');
  };

  public static changeLanguage = (language: FreshDeskValidLanguages) => {
    if ('fwSettings' in window) {
      if (typeof window.fwSettings === 'object' && window.fwSettings !== null) {
        Object.assign(window.fwSettings, { language });

        FreshDeskSDK.destroy();
        FreshDeskSDK.boot();
      }
    }
  };

  private static call(...args: any[]) {
    try {
      const fn = (
        window as unknown as { FreshworksWidget: (...args: any[]) => any }
      ).FreshworksWidget;

      fn(...args);
    } catch (error) {
      console.error('FreshDeskSDK call error', error);
    }
  }
}

export { FreshDeskSDK };
