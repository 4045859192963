export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` custom scalar type */
  BigInt: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Decimal` custom scalar type */
  Decimal: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};




export type ApiKeyOrderByInput = {
  name?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  endAt?: Maybe<SortOrder>;
};

export type ApiKeyOutput = {
  id: Scalars['String'];
  name: Scalars['String'];
  type: TypeOfSignature;
  publicKey?: Maybe<Scalars['String']>;
  merchantId: Scalars['String'];
  creatorUserId: Scalars['String'];
  allowedOperations: Array<CompanyAllowedOperationEnum>;
  payload: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  endAt?: Maybe<Scalars['DateTime']>;
  ipWhiteList: Array<Scalars['String']>;
};

export type AssetNullableRelationFilter = {
  is?: Maybe<AssetWhereInput>;
  isNot?: Maybe<AssetWhereInput>;
};

export type AssetOrderByInput = {
  code?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  rateFreezeSeconds?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type AssetPaymentMethod = {
  id: Scalars['String'];
  code: Scalars['String'];
  asset: Scalars['String'];
  transferType: TransferType;
  merchantId: Scalars['String'];
  network?: Maybe<Scalars['String']>;
  processor: Scalars['String'];
  payload: Scalars['JSON'];
  position: Scalars['Int'];
  saasOperation: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  customTitle?: Maybe<Scalars['String']>;
  purposeField?: Maybe<Scalars['String']>;
  isPrefill?: Maybe<Scalars['Boolean']>;
  maxRequisiteCount?: Maybe<Scalars['Int']>;
  isRequisiteUpdateDisabled?: Maybe<Scalars['Boolean']>;
  isRequisiteDeleteDisabled?: Maybe<Scalars['Boolean']>;
  trustWalletCoinId?: Maybe<Scalars['String']>;
  fields: Array<PaymentMethodField>;
  messages: Array<PaymentMethodMessage>;
  Product: ProductOutput;
  Asset?: Maybe<PublicAssetOutput>;
};

export type AssetRelationFilter = {
  is?: Maybe<AssetWhereInput>;
  isNot?: Maybe<AssetWhereInput>;
};

export type AssetSettingsOutput = {
  invoiceCoefficient: Scalars['Decimal'];
};

export enum AssetType {
  Crypto = 'Crypto',
  Fiat = 'Fiat'
}

export type AssetWhereInput = {
  AND?: Maybe<Array<AssetWhereInput>>;
  OR?: Maybe<Array<AssetWhereInput>>;
  NOT?: Maybe<Array<AssetWhereInput>>;
  code?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  position?: Maybe<IntFilter>;
  precision?: Maybe<IntFilter>;
  payload?: Maybe<JsonFilter>;
  type?: Maybe<EnumAssetTypeFilter>;
  enabled?: Maybe<BoolFilter>;
  hidden?: Maybe<BoolFilter>;
  rateFreezeSeconds?: Maybe<IntFilter>;
  minInvoiceAmount?: Maybe<DecimalFilter>;
  defaultInvoiceCoefficient?: Maybe<DecimalFilter>;
  isInvoiceCurrency?: Maybe<BoolFilter>;
  isPaymentCurrency?: Maybe<BoolFilter>;
  isReferenceCurrency?: Maybe<BoolFilter>;
  isAutoConvertCurrency?: Maybe<BoolFilter>;
  isReferralPayoutCurrency?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  PaymentMethod?: Maybe<PaymentMethodListRelationFilter>;
  Transfer?: Maybe<TransferListRelationFilter>;
  TransferLimit?: Maybe<TransferLimitListRelationFilter>;
  InvoiceWithAsset?: Maybe<InvoiceListRelationFilter>;
  InvoiceWithPaymentAsset?: Maybe<InvoiceListRelationFilter>;
  CompanyWithAutoConvertCurrency?: Maybe<CompanyListRelationFilter>;
  CompanyWithReferenceCurrency?: Maybe<CompanyListRelationFilter>;
  CompanyReferralPayoutCurrency?: Maybe<CompanyListRelationFilter>;
  CompanyAssetSettings?: Maybe<CompanyAssetSettingsListRelationFilter>;
  ConvertFrom?: Maybe<ConvertListRelationFilter>;
  ConvertTo?: Maybe<ConvertListRelationFilter>;
  Requisite?: Maybe<RequisiteListRelationFilter>;
  PayoutPaymentSummary?: Maybe<PayoutPaymentSummaryListRelationFilter>;
};

export type AuthTokensOutput = {
  /** When access token will expire */
  expiresIn: Scalars['Int'];
  /** Jwt token with session info */
  accessToken: Scalars['String'];
  /** Token for refresh access token */
  refreshToken: Scalars['String'];
};

export type AuthenticateFreshDeskOutput = {
  /** FreshDesk token */
  token: Scalars['String'];
};

export type AutoConvertPaymentMethodPublicOutput = {
  name: Scalars['String'];
  Product: PublicProductOutput;
};

export type AvailableCoworkerRolesOutput = {
  roles: Array<Scalars['String']>;
};

export enum AvailableLanguages {
  En = 'en',
  Uk = 'uk',
  De = 'de',
  Es = 'es',
  It = 'it',
  Lt = 'lt',
  Pl = 'pl',
  Pt = 'pt',
  Fr = 'fr'
}

export type AvailableRabacRolesOutput = {
  roles: Array<Scalars['String']>;
};

export type AvailableTypesForCreateCompanyOutput = {
  types: Array<CompanyType>;
};


export type BigIntFilter = {
  equals?: Maybe<Scalars['BigInt']>;
  in?: Maybe<Array<Scalars['BigInt']>>;
  notIn?: Maybe<Array<Scalars['BigInt']>>;
  lt?: Maybe<Scalars['BigInt']>;
  lte?: Maybe<Scalars['BigInt']>;
  gt?: Maybe<Scalars['BigInt']>;
  gte?: Maybe<Scalars['BigInt']>;
  not?: Maybe<NestedBigIntFilter>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type CpuDetails = {
  /** Value examples: '68k', 'amd64', 'arm', 'arm64', 'avr'... */
  architecture?: Maybe<Scalars['String']>;
};

export type CalculatedCompanyLimitOutput = {
  isExceeded: Scalars['Boolean'];
  /** Left amount according to limits */
  leftAmount: Scalars['Decimal'];
  usedAmount: Scalars['Decimal'];
  CompanyLimit?: Maybe<CompanyLimitOutput>;
};

export type CheckCompanyExistOutput = {
  exists: Scalars['Boolean'];
};

export type CheckResult = {
  ok: Scalars['Boolean'];
  name: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
};

export type CheckUsernameOutput = {
  exists: Scalars['Boolean'];
};

export type CocktailPromoOutput = {
  paymentAmount: Scalars['String'];
  equivalentAmount: Scalars['String'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  Company: NonAuthCompanyDetailsOutput;
  PaymentAsset: PublicAssetOutput;
  EquivalentAsset: PublicAssetOutput;
  Address: NonAuthDepositAddressOutput;
  Method: PublicPaymentMethodOutput;
};

export type Company = {
  id: Scalars['String'];
  title: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  referenceCurrency: Scalars['String'];
  referralPayoutCurrency: Scalars['String'];
  autoConversionCurrency?: Maybe<Scalars['String']>;
  supportInfo?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  merchantId: Scalars['String'];
  isApiBlocked: Scalars['Boolean'];
  isSuspicious: Scalars['Boolean'];
  isLimitsExceeded: Scalars['Boolean'];
  isVolatilityCoverageEnabled: Scalars['Boolean'];
  identificationStatus: IdentificationStatus;
  identReminderSentAt?: Maybe<Scalars['DateTime']>;
  identCompletedAt?: Maybe<Scalars['DateTime']>;
  invoiceReminderSentAt?: Maybe<Scalars['DateTime']>;
  invoiceUnpaidPercent: Scalars['Decimal'];
  type: CompanyType;
  status: StatusOfCompany;
  kycSource: CompanyKycSource;
  kycId?: Maybe<Scalars['String']>;
  referralExpirationSeconds?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  payload: Scalars['JSON'];
  industry?: Maybe<Scalars['String']>;
  invoiceFeeDiscountEndAt?: Maybe<Scalars['DateTime']>;
  invoiceFeeDiscountReminderSentAt?: Maybe<Scalars['DateTime']>;
  invoiceFeeDiscountDisabledAt?: Maybe<Scalars['DateTime']>;
};

export enum CompanyAllowedOperationEnum {
  Read = 'Read',
  Invoicing = 'Invoicing',
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal'
}

export type CompanyAsset = {
  balance: Scalars['Decimal'];
  lockBalance: Scalars['Decimal'];
  suspendedBalance: Scalars['Decimal'];
  arrestedBalance: Scalars['Decimal'];
  Asset: PublicAssetOutput;
};

export type CompanyAssetSettingsListRelationFilter = {
  every?: Maybe<CompanyAssetSettingsWhereInput>;
  some?: Maybe<CompanyAssetSettingsWhereInput>;
  none?: Maybe<CompanyAssetSettingsWhereInput>;
};

export type CompanyAssetSettingsWhereInput = {
  AND?: Maybe<Array<CompanyAssetSettingsWhereInput>>;
  OR?: Maybe<Array<CompanyAssetSettingsWhereInput>>;
  NOT?: Maybe<Array<CompanyAssetSettingsWhereInput>>;
  assetCode?: Maybe<StringFilter>;
  companyId?: Maybe<StringFilter>;
  invoiceCoefficient?: Maybe<DecimalFilter>;
  Asset?: Maybe<AssetRelationFilter>;
  Company?: Maybe<CompanyRelationFilter>;
};

export type CompanyDetailsOutput = {
  id: Scalars['String'];
  title: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  referenceCurrency: Scalars['String'];
  referralPayoutCurrency: Scalars['String'];
  autoConversionCurrency?: Maybe<Scalars['String']>;
  supportInfo?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  merchantId: Scalars['String'];
  isApiBlocked: Scalars['Boolean'];
  isSuspicious: Scalars['Boolean'];
  isLimitsExceeded: Scalars['Boolean'];
  isVolatilityCoverageEnabled: Scalars['Boolean'];
  identificationStatus: IdentificationStatus;
  identReminderSentAt?: Maybe<Scalars['DateTime']>;
  identCompletedAt?: Maybe<Scalars['DateTime']>;
  invoiceReminderSentAt?: Maybe<Scalars['DateTime']>;
  invoiceUnpaidPercent: Scalars['Decimal'];
  type: CompanyType;
  status: StatusOfCompany;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  payload: Scalars['JSON'];
  industry?: Maybe<Scalars['String']>;
  invoiceFeeDiscountEndAt?: Maybe<Scalars['DateTime']>;
  invoiceFeeDiscountReminderSentAt?: Maybe<Scalars['DateTime']>;
  invoiceFeeDiscountDisabledAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Will be removed, need use `CompanyOwner` */
  companyOwner: CompanyOwnerOutput;
  ReferenceCurrencyAsset: PublicAssetOutput;
  AutoConvertAsset?: Maybe<PublicAssetOutput>;
  ReferralPayoutAsset: PublicAssetOutput;
  CompanyOwner: CompanyOwnerOutput;
};

export type CompanyIdOutput = {
  /** Company ID */
  id: Scalars['String'];
};

export enum CompanyKycSource {
  KunaPay = 'KUNA_PAY',
  KunaPro = 'KUNA_PRO'
}

export type CompanyLimitOutput = {
  id: Scalars['String'];
  limit: Scalars['Decimal'];
  companyType: CompanyType;
  type: CompanyLimitType;
  period: CompanyLimitPeriod;
};

export enum CompanyLimitPeriod {
  Day = 'Day',
  Month = 'Month'
}

export enum CompanyLimitType {
  Income = 'Income',
  Outcome = 'Outcome'
}

export type CompanyListRelationFilter = {
  every?: Maybe<CompanyWhereInput>;
  some?: Maybe<CompanyWhereInput>;
  none?: Maybe<CompanyWhereInput>;
};

export enum CompanyNotificationEntities {
  AutoConvert = 'autoConvert',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Invoice = 'invoice',
  Payout = 'payout',
  ReferralProgram = 'referralProgram'
}

export type CompanyNotificationSettingsOutput = {
  autoConvert: NotificationSettingsDetailsOutput;
  deposit: NotificationSettingsDetailsOutput;
  invoice: NotificationSettingsDetailsOutput;
  payout: NotificationSettingsDetailsOutput;
  referralProgram: NotificationSettingsDetailsOutput;
  withdraw: NotificationSettingsDetailsOutput;
};

export type CompanyNullableRelationFilter = {
  is?: Maybe<CompanyWhereInput>;
  isNot?: Maybe<CompanyWhereInput>;
};

export type CompanyOwnerOutput = {
  /** Owner user id */
  id: Scalars['String'];
  /** Owner email */
  email: Scalars['String'];
  /** Is owner KUNA employee */
  isKUNAEmployee: Scalars['Boolean'];
};

export type CompanyPayloadInput = {
  goal?: Maybe<Scalars['String']>;
  sourceOfKnowledge: Scalars['String'];
  averageTransactionAmount?: Maybe<Scalars['String']>;
  monthlyTurnover?: Maybe<Scalars['String']>;
};

export type CompanyReferralListRelationFilter = {
  every?: Maybe<CompanyReferralWhereInput>;
  some?: Maybe<CompanyReferralWhereInput>;
  none?: Maybe<CompanyReferralWhereInput>;
};

export type CompanyReferralWhereInput = {
  AND?: Maybe<Array<CompanyReferralWhereInput>>;
  OR?: Maybe<Array<CompanyReferralWhereInput>>;
  NOT?: Maybe<Array<CompanyReferralWhereInput>>;
  id?: Maybe<StringFilter>;
  affiliateCompanyId?: Maybe<StringFilter>;
  referralCompanyId?: Maybe<StringFilter>;
  expireAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  Affiliate?: Maybe<CompanyRelationFilter>;
  Referral?: Maybe<CompanyRelationFilter>;
};

export type CompanyRelationCoworkerProfileOrderByInput = {
  id?: Maybe<SortOrder>;
  username?: Maybe<SortOrder>;
  fullName?: Maybe<SortOrder>;
};

export type CompanyRelationDetailsOrderByInput = {
  companyId?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  Profile?: Maybe<CompanyRelationCoworkerProfileOrderByInput>;
};

export type CompanyRelationFilter = {
  is?: Maybe<CompanyWhereInput>;
  isNot?: Maybe<CompanyWhereInput>;
};

export type CompanyRelationOutput = {
  companyId: Scalars['String'];
  userId: Scalars['String'];
  role: Scalars['String'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  Profile: CoworkerInfo;
};

export type CompanyStatisticByInvoicesOutput = {
  paid: Scalars['Decimal'];
  pending: Scalars['Decimal'];
  timeout: Scalars['Decimal'];
  Asset: PublicAssetOutput;
};

export type CompanyTransferOrderInput = {
  status?: Maybe<SortOrder>;
  asset?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type CompanyTransferOutput = {
  id: Scalars['String'];
  status: TransferStatus;
  type: TransferType;
  invoiceId?: Maybe<Scalars['String']>;
  asset: Scalars['String'];
  amount: Scalars['Decimal'];
  processedAmount?: Maybe<Scalars['Decimal']>;
  fee?: Maybe<Scalars['Decimal']>;
  companyId: Scalars['String'];
  paymentCode: Scalars['String'];
  reason: Array<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  convertId?: Maybe<Scalars['String']>;
  payoutOperationId?: Maybe<Scalars['String']>;
  isCreatedByApi: Scalars['Boolean'];
  creatorId?: Maybe<Scalars['String']>;
  creatorComment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  merchantId: Scalars['String'];
  logicalTime: Scalars['BigInt'];
  PaymentMethod: PaymentMethod;
  Company: Company;
  TransferStatusHistory?: Maybe<Array<TransferStatusHistory>>;
  Asset: PublicAssetOutput;
  Invoice?: Maybe<InvoiceDetailsOutput>;
  Convert?: Maybe<ConvertPublicOutput>;
  PayoutOperation?: Maybe<PublicPayoutOperationOutput>;
  Creator?: Maybe<InitiatorProfileDetailsOutput>;
  APIKey?: Maybe<TransferApiKeyCreatorOutput>;
  AdditionalFields: Array<TransferAdditionalFieldsOutput>;
  Details: TransferDetailsOutput;
  AmlDetails?: Maybe<TransferAmlDetailsForCompanyOutput>;
};

export enum CompanyType {
  LegalEntity = 'LEGAL_ENTITY',
  Entrepreneur = 'ENTREPRENEUR',
  Basic = 'BASIC'
}

export type CompanyUserUniqueInput = {
  /** Coworker User ID */
  userId: Scalars['String'];
};

export type CompanyWhereInput = {
  AND?: Maybe<Array<CompanyWhereInput>>;
  OR?: Maybe<Array<CompanyWhereInput>>;
  NOT?: Maybe<Array<CompanyWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  avatar?: Maybe<StringNullableFilter>;
  referenceCurrency?: Maybe<StringFilter>;
  referralPayoutCurrency?: Maybe<StringFilter>;
  autoConversionCurrency?: Maybe<StringNullableFilter>;
  supportInfo?: Maybe<StringNullableFilter>;
  websiteUrl?: Maybe<StringNullableFilter>;
  merchantId?: Maybe<StringFilter>;
  isApiBlocked?: Maybe<BoolFilter>;
  isSuspicious?: Maybe<BoolFilter>;
  isLimitsExceeded?: Maybe<BoolFilter>;
  isVolatilityCoverageEnabled?: Maybe<BoolFilter>;
  identificationStatus?: Maybe<EnumIdentificationStatusFilter>;
  identReminderSentAt?: Maybe<DateTimeNullableFilter>;
  identCompletedAt?: Maybe<DateTimeNullableFilter>;
  invoiceReminderSentAt?: Maybe<DateTimeNullableFilter>;
  invoiceUnpaidPercent?: Maybe<DecimalFilter>;
  type?: Maybe<EnumCompanyTypeFilter>;
  status?: Maybe<EnumStatusOfCompanyFilter>;
  kycSource?: Maybe<EnumCompanyKycSourceFilter>;
  kycId?: Maybe<StringNullableFilter>;
  referralExpirationSeconds?: Maybe<IntNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  payload?: Maybe<JsonFilter>;
  industry?: Maybe<StringNullableFilter>;
  invoiceFeeDiscountEndAt?: Maybe<DateTimeNullableFilter>;
  invoiceFeeDiscountReminderSentAt?: Maybe<DateTimeNullableFilter>;
  invoiceFeeDiscountDisabledAt?: Maybe<DateTimeNullableFilter>;
  UserRelation?: Maybe<RelationUserToCompanyListRelationFilter>;
  Note?: Maybe<NoteListRelationFilter>;
  Invoices?: Maybe<InvoiceListRelationFilter>;
  TransferLimit?: Maybe<TransferLimitListRelationFilter>;
  AutoConvertAsset?: Maybe<AssetNullableRelationFilter>;
  ReferenceCurrencyAsset?: Maybe<AssetRelationFilter>;
  ReferralPayoutAsset?: Maybe<AssetRelationFilter>;
  DepositAddress?: Maybe<DepositAddressListRelationFilter>;
  Transfer?: Maybe<TransferListRelationFilter>;
  InvitedCoworker?: Maybe<InvitedCoworkerListRelationFilter>;
  CompanyAssetSettings?: Maybe<CompanyAssetSettingsListRelationFilter>;
  Convert?: Maybe<ConvertListRelationFilter>;
  Requisite?: Maybe<RequisiteListRelationFilter>;
  Zen?: Maybe<ZenNullableRelationFilter>;
  Questionnaire?: Maybe<QuestionnaireListRelationFilter>;
  Payout?: Maybe<PayoutListRelationFilter>;
  ReferralUser?: Maybe<ProfileListRelationFilter>;
  Referral?: Maybe<CompanyReferralListRelationFilter>;
  Affiliate?: Maybe<CompanyReferralListRelationFilter>;
  Notification?: Maybe<NotificationListRelationFilter>;
  NotificationSettings?: Maybe<CustomNotificationSettingsListRelationFilter>;
};

/** Allowed to enable confirm entities */
export enum ConfirmationEntities {
  Withdraw = 'withdraw'
}

export type ConfirmationInput = {
  entity: ConfirmationEntities;
  isEnabled: Scalars['Boolean'];
};

export type ConfirmationSetting = {
  withdraw: Scalars['Boolean'];
};

export type ConfirmationSettingNullableRelationFilter = {
  is?: Maybe<ConfirmationSettingWhereInput>;
  isNot?: Maybe<ConfirmationSettingWhereInput>;
};

export type ConfirmationSettingWhereInput = {
  AND?: Maybe<Array<ConfirmationSettingWhereInput>>;
  OR?: Maybe<Array<ConfirmationSettingWhereInput>>;
  NOT?: Maybe<Array<ConfirmationSettingWhereInput>>;
  id?: Maybe<StringFilter>;
  withdraw?: Maybe<BoolFilter>;
  Profile?: Maybe<ProfileRelationFilter>;
};

export type ConvertListRelationFilter = {
  every?: Maybe<ConvertWhereInput>;
  some?: Maybe<ConvertWhereInput>;
  none?: Maybe<ConvertWhereInput>;
};

export type ConvertNullableRelationFilter = {
  is?: Maybe<ConvertWhereInput>;
  isNot?: Maybe<ConvertWhereInput>;
};

export type ConvertPublicOutput = {
  id: Scalars['String'];
  companyId: Scalars['String'];
  fromCurrency: Scalars['String'];
  fromAmount: Scalars['Decimal'];
  toCurrency: Scalars['String'];
  amount: Scalars['Decimal'];
  fee: Scalars['Decimal'];
  processedAmount: Scalars['Decimal'];
  status: ConvertStatus;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  FromCurrency: PublicAssetOutput;
  ToCurrency: PublicAssetOutput;
};

export type ConvertPublicWhereInput = {
  id?: Maybe<StringFilter>;
  fromCurrency?: Maybe<StringFilter>;
  toCurrency?: Maybe<StringFilter>;
  status?: Maybe<EnumConvertStatusFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum ConvertStatus {
  Created = 'Created',
  Processing = 'Processing',
  Processed = 'Processed',
  Canceled = 'Canceled'
}

export enum ConvertSupportStatus {
  Created = 'Created',
  Processing = 'Processing',
  Processed = 'Processed',
  TechTrouble = 'TechTrouble',
  Canceled = 'Canceled'
}

export type ConvertWhereInput = {
  AND?: Maybe<Array<ConvertWhereInput>>;
  OR?: Maybe<Array<ConvertWhereInput>>;
  NOT?: Maybe<Array<ConvertWhereInput>>;
  id?: Maybe<StringFilter>;
  companyId?: Maybe<StringFilter>;
  fromCurrency?: Maybe<StringFilter>;
  fromAmount?: Maybe<DecimalFilter>;
  toCurrency?: Maybe<StringFilter>;
  amount?: Maybe<DecimalFilter>;
  fee?: Maybe<DecimalFilter>;
  processedAmount?: Maybe<DecimalFilter>;
  reason?: Maybe<StringNullableListFilter>;
  payload?: Maybe<JsonFilter>;
  status?: Maybe<EnumConvertStatusFilter>;
  supportStatus?: Maybe<EnumConvertSupportStatusFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  FromCurrency?: Maybe<AssetRelationFilter>;
  ToCurrency?: Maybe<AssetRelationFilter>;
  Company?: Maybe<CompanyRelationFilter>;
  Transfer?: Maybe<TransferNullableRelationFilter>;
};

export type CountOfActiveReferralByCompany = {
  count: Scalars['Int'];
};

export type CoworkerInfo = {
  id: Scalars['String'];
  username: Scalars['String'];
  fullName: Scalars['String'];
  email: Scalars['String'];
};

export type CreateBusinessAccountLink = {
  confirmationUrl: Scalars['String'];
};

export type CreateInvoiceCustomizationInput = {
  redirectUrl?: Maybe<Scalars['String']>;
  redirectButtonTitle?: Maybe<Scalars['String']>;
};

export type CreateInvoiceOutput = {
  /** Unique record identifier */
  id: Scalars['String'];
  PaymentLink: InvoicePaymentLinkOutput;
};

export type CreateInvoiceReportWhereInput = {
  invoiceId?: Maybe<Scalars['String']>;
  assets?: Maybe<Array<Scalars['String']>>;
  statuses?: Maybe<Array<InvoiceStatus>>;
  dateRange: DateRangeInput;
};

export type CreateOneCompanyInput = {
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  /** The reference currency of the company */
  referenceCurrency: Scalars['String'];
  websiteUrl?: Maybe<Scalars['String']>;
  type: CompanyType;
  industry?: Maybe<Scalars['String']>;
  payload?: Maybe<CompanyPayloadInput>;
};

export type CreatePayoutContractorInput = {
  /** Contractor name */
  name: Scalars['String'];
  amount: Scalars['String'];
  asset: Scalars['String'];
  paymentMethodCode: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  fields?: Maybe<Scalars['JSON']>;
  externalId?: Maybe<Scalars['String']>;
};

export type CreatePayoutOutput = {
  success: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  issues?: Maybe<Array<PayoutIssueOutput>>;
};

export type CreateQuestionnaireFieldInput = {
  name: Scalars['String'];
};

export type CreateQuestionnaireInput = {
  schemaFields: Array<CreateQuestionnaireFieldInput>;
};

export type CreateReportSettings = {
  /** dayjs timezone */
  timeZone?: Maybe<Scalars['String']>;
  format?: Maybe<ReportFileFormat>;
};

export type CreateReportWhereInput = {
  type?: Maybe<EnumTransferTypeFilter>;
  asset?: Maybe<StringFilter>;
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};

export type CreatedReportOutput = {
  url: Scalars['String'];
};

export type CreatedTransactionReportOutput = {
  url: Scalars['String'];
};

export type CustomNotificationSettingsListRelationFilter = {
  every?: Maybe<CustomNotificationSettingsWhereInput>;
  some?: Maybe<CustomNotificationSettingsWhereInput>;
  none?: Maybe<CustomNotificationSettingsWhereInput>;
};

export type CustomNotificationSettingsWhereInput = {
  AND?: Maybe<Array<CustomNotificationSettingsWhereInput>>;
  OR?: Maybe<Array<CustomNotificationSettingsWhereInput>>;
  NOT?: Maybe<Array<CustomNotificationSettingsWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  companyId?: Maybe<StringNullableFilter>;
  entity?: Maybe<StringFilter>;
  push?: Maybe<BoolFilter>;
  email?: Maybe<BoolFilter>;
  Profile?: Maybe<ProfileRelationFilter>;
  Company?: Maybe<CompanyNullableRelationFilter>;
};

export type DateRangeInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type DateRangeOutput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};


export type DecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type DecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
};

export type DepositAddress = {
  id: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  address: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  payload: Scalars['JSON'];
  type: DepositAddressType;
  merchantId: Scalars['String'];
  expireAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

export type DepositAddressListRelationFilter = {
  every?: Maybe<DepositAddressWhereInput>;
  some?: Maybe<DepositAddressWhereInput>;
  none?: Maybe<DepositAddressWhereInput>;
};

export type DepositAddressNullableRelationFilter = {
  is?: Maybe<DepositAddressWhereInput>;
  isNot?: Maybe<DepositAddressWhereInput>;
};

export type DepositAddressPublicOutput = {
  id: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  address: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  type: DepositAddressType;
  expireAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
};

export enum DepositAddressType {
  Const = 'Const',
  Invoice = 'Invoice',
  OneTime = 'OneTime'
}

export type DepositAddressWhereInput = {
  AND?: Maybe<Array<DepositAddressWhereInput>>;
  OR?: Maybe<Array<DepositAddressWhereInput>>;
  NOT?: Maybe<Array<DepositAddressWhereInput>>;
  id?: Maybe<StringFilter>;
  companyId?: Maybe<StringNullableFilter>;
  source?: Maybe<StringFilter>;
  address?: Maybe<StringFilter>;
  memo?: Maybe<StringNullableFilter>;
  payload?: Maybe<JsonFilter>;
  type?: Maybe<EnumDepositAddressTypeFilter>;
  merchantId?: Maybe<StringFilter>;
  expireAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  Company?: Maybe<CompanyNullableRelationFilter>;
  Invoice?: Maybe<InvoiceNullableRelationFilter>;
};

export type DeviceDetails = {
  model?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  /** Value examples: 'HP', 'Nokia', 'Apple', 'Lenovo'... */
  vendor?: Maybe<Scalars['String']>;
};

export type DeviceInfo = {
  cpu: CpuDetails;
  os: OsDetails;
  device: DeviceDetails;
};

export enum DeviceType {
  Console = 'console',
  Mobile = 'mobile',
  Tablet = 'tablet',
  Smarttv = 'smarttv',
  Wearable = 'wearable',
  Embedded = 'embedded'
}

export type DonationPaymentDetailsForNonAuthOutput = {
  processedAmount: Scalars['String'];
  amount: Scalars['String'];
  fee: Scalars['String'];
};

export enum EndpointPrefix {
  Api = 'api'
}

export type EnumAssetTypeFilter = {
  equals?: Maybe<AssetType>;
  in?: Maybe<Array<AssetType>>;
  notIn?: Maybe<Array<AssetType>>;
  not?: Maybe<NestedEnumAssetTypeFilter>;
};

export type EnumCompanyKycSourceFilter = {
  equals?: Maybe<CompanyKycSource>;
  in?: Maybe<Array<CompanyKycSource>>;
  notIn?: Maybe<Array<CompanyKycSource>>;
  not?: Maybe<NestedEnumCompanyKycSourceFilter>;
};

export type EnumCompanyLimitPeriodFilter = {
  equals?: Maybe<CompanyLimitPeriod>;
  in?: Maybe<Array<CompanyLimitPeriod>>;
  notIn?: Maybe<Array<CompanyLimitPeriod>>;
  not?: Maybe<NestedEnumCompanyLimitPeriodFilter>;
};

export type EnumCompanyLimitTypeFilter = {
  equals?: Maybe<CompanyLimitType>;
  in?: Maybe<Array<CompanyLimitType>>;
  notIn?: Maybe<Array<CompanyLimitType>>;
  not?: Maybe<NestedEnumCompanyLimitTypeFilter>;
};

export type EnumCompanyTypeFilter = {
  equals?: Maybe<CompanyType>;
  in?: Maybe<Array<CompanyType>>;
  notIn?: Maybe<Array<CompanyType>>;
  not?: Maybe<NestedEnumCompanyTypeFilter>;
};

export type EnumConvertStatusFilter = {
  equals?: Maybe<ConvertStatus>;
  in?: Maybe<Array<ConvertStatus>>;
  notIn?: Maybe<Array<ConvertStatus>>;
  not?: Maybe<NestedEnumConvertStatusFilter>;
};

export type EnumConvertSupportStatusFilter = {
  equals?: Maybe<ConvertSupportStatus>;
  in?: Maybe<Array<ConvertSupportStatus>>;
  notIn?: Maybe<Array<ConvertSupportStatus>>;
  not?: Maybe<NestedEnumConvertSupportStatusFilter>;
};

export type EnumDepositAddressTypeFilter = {
  equals?: Maybe<DepositAddressType>;
  in?: Maybe<Array<DepositAddressType>>;
  notIn?: Maybe<Array<DepositAddressType>>;
  not?: Maybe<NestedEnumDepositAddressTypeFilter>;
};

export type EnumIdentificationStatusFilter = {
  equals?: Maybe<IdentificationStatus>;
  in?: Maybe<Array<IdentificationStatus>>;
  notIn?: Maybe<Array<IdentificationStatus>>;
  not?: Maybe<NestedEnumIdentificationStatusFilter>;
};

export type EnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type EnumPayoutOperationStatusFilter = {
  equals?: Maybe<PayoutOperationStatus>;
  in?: Maybe<Array<PayoutOperationStatus>>;
  notIn?: Maybe<Array<PayoutOperationStatus>>;
  not?: Maybe<NestedEnumPayoutOperationStatusFilter>;
};

export type EnumPayoutStatusFilter = {
  equals?: Maybe<PayoutStatus>;
  in?: Maybe<Array<PayoutStatus>>;
  notIn?: Maybe<Array<PayoutStatus>>;
  not?: Maybe<NestedEnumPayoutStatusFilter>;
};

export type EnumPayoutSupportStatusFilter = {
  equals?: Maybe<PayoutSupportStatus>;
  in?: Maybe<Array<PayoutSupportStatus>>;
  notIn?: Maybe<Array<PayoutSupportStatus>>;
  not?: Maybe<NestedEnumPayoutSupportStatusFilter>;
};

export type EnumReportStatusFilter = {
  equals?: Maybe<ReportStatus>;
  in?: Maybe<Array<ReportStatus>>;
  notIn?: Maybe<Array<ReportStatus>>;
  not?: Maybe<NestedEnumReportStatusFilter>;
};

export type EnumReportTypeFilter = {
  equals?: Maybe<ReportType>;
  in?: Maybe<Array<ReportType>>;
  notIn?: Maybe<Array<ReportType>>;
  not?: Maybe<NestedEnumReportTypeFilter>;
};

export type EnumRequisiteTypeFilter = {
  equals?: Maybe<RequisiteType>;
  in?: Maybe<Array<RequisiteType>>;
  notIn?: Maybe<Array<RequisiteType>>;
  not?: Maybe<NestedEnumRequisiteTypeFilter>;
};

export type EnumStatusOfCompanyFilter = {
  equals?: Maybe<StatusOfCompany>;
  in?: Maybe<Array<StatusOfCompany>>;
  notIn?: Maybe<Array<StatusOfCompany>>;
  not?: Maybe<NestedEnumStatusOfCompanyFilter>;
};

export type EnumStatusOfUserFilter = {
  equals?: Maybe<StatusOfUser>;
  in?: Maybe<Array<StatusOfUser>>;
  notIn?: Maybe<Array<StatusOfUser>>;
  not?: Maybe<NestedEnumStatusOfUserFilter>;
};

export type EnumTransferStatusFilter = {
  equals?: Maybe<TransferStatus>;
  in?: Maybe<Array<TransferStatus>>;
  notIn?: Maybe<Array<TransferStatus>>;
  not?: Maybe<NestedEnumTransferStatusFilter>;
};

export type EnumTransferStatusSupportFilter = {
  equals?: Maybe<TransferStatusSupport>;
  in?: Maybe<Array<TransferStatusSupport>>;
  notIn?: Maybe<Array<TransferStatusSupport>>;
  not?: Maybe<NestedEnumTransferStatusSupportFilter>;
};

export type EnumTransferTypeFilter = {
  equals?: Maybe<TransferType>;
  in?: Maybe<Array<TransferType>>;
  notIn?: Maybe<Array<TransferType>>;
  not?: Maybe<NestedEnumTransferTypeFilter>;
};

export type EnumZenStatusFilter = {
  equals?: Maybe<ZenStatus>;
  in?: Maybe<Array<ZenStatus>>;
  notIn?: Maybe<Array<ZenStatus>>;
  not?: Maybe<NestedEnumZenStatusFilter>;
};

export type Equivalent = {
  currency: Scalars['String'];
  amount: Scalars['String'];
};

export type Exception = {
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ExecuteInvoiceOutput = {
  /** Invoice ID */
  id: Scalars['String'];
  /** Address for deposit */
  address: Scalars['String'];
  /** Memo/destination tag */
  memo?: Maybe<Scalars['String']>;
};

export type Feature = {
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type FeedbackMeta = {
  userEmail?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
};

export type FilterForStringInJson = {
  equals?: Maybe<Scalars['JSON']>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
};

export type FindCompanyPayoutDetailsOutput = {
  id: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isCreatedByApi: Scalars['Boolean'];
  status: PayoutStatus;
  completedAt?: Maybe<Scalars['DateTime']>;
  launchedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  PayoutPaymentSummary: Array<PublicPayoutPaymentSummaryOutput>;
  Initiators: PayoutInitiatorsOutput;
};

export type FindCompanyRelationWhereInput = {
  role?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
};

export type FindCountNotReadNotificationByGroupOutput = {
  group: Scalars['String'];
  count: Scalars['Int'];
};

export type FindCountNotReadNotificationOutput = {
  count: Scalars['Int'];
};

export type FindDetailsForDonationPageNonAuthOutput = {
  Company: NonAuthCompanyDetailsOutput;
  PaymentMethods: Array<AssetPaymentMethod>;
  EquivalentAsset: PublicAssetOutput;
};

export type FindDonationForNonAuthOutput = {
  id: Scalars['String'];
  address: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
  PaymentMethod: AssetPaymentMethod;
  PaymentDetails: DonationPaymentDetailsForNonAuthOutput;
  EquivalentAsset: PublicAssetOutput;
};

export type FindInvitedCoworkerOutput = {
  id?: Maybe<Scalars['String']>;
  emailHash?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<Company>;
  InvitedCoworkerSecretData?: Maybe<InvitedCoworkerSecretData>;
};

export type FindManyAvailablePaymentMethodOrderByInput = {
  code?: Maybe<SortOrder>;
  asset?: Maybe<SortOrder>;
  position?: Maybe<SortOrder>;
};

export type FindManyAvailablePaymentMethodWhereInput = {
  code?: Maybe<StringFilter>;
  asset?: Maybe<StringFilter>;
  network?: Maybe<StringNullableFilter>;
  id?: Maybe<Scalars['String']>;
  transferType: TransferType;
};

export type FindManyCompanyLimitWhere = {
  companyType?: Maybe<EnumCompanyTypeFilter>;
  type?: Maybe<EnumCompanyLimitTypeFilter>;
  period?: Maybe<EnumCompanyLimitPeriodFilter>;
};

export type FindManyCompanyPayoutOutput = {
  id: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isCreatedByApi: Scalars['Boolean'];
  status: PayoutStatus;
  completedAt?: Maybe<Scalars['DateTime']>;
  launchedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type FindManyCompanyPayoutWhereInput = {
  id?: Maybe<StringFilter>;
  externalId?: Maybe<StringNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumPayoutStatusFilter>;
  supportStatus?: Maybe<EnumPayoutSupportStatusFilter>;
  AND?: Maybe<Array<FindManyCompanyPayoutWhereInput>>;
  OR?: Maybe<Array<FindManyCompanyPayoutWhereInput>>;
  NOT?: Maybe<Array<FindManyCompanyPayoutWhereInput>>;
};

export type FindManyCompanyTransferWhereInput = {
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumTransferStatusFilter>;
  type?: Maybe<EnumTransferTypeFilter>;
  asset?: Maybe<StringFilter>;
  paymentCode?: Maybe<StringFilter>;
  address?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  /** Transaction id in Blockchain */
  txId?: Maybe<FilterForStringInJson>;
  AND?: Maybe<Array<FindManyCompanyTransferWhereInput>>;
  OR?: Maybe<Array<FindManyCompanyTransferWhereInput>>;
  NOT?: Maybe<Array<FindManyCompanyTransferWhereInput>>;
  Convert?: Maybe<ConvertPublicWhereInput>;
  PayoutOperation?: Maybe<PayoutOperationPublicWhereInput>;
};

export type FindManyInvoiceOrderByInput = {
  id?: Maybe<SortOrder>;
  invoiceAssetCode?: Maybe<SortOrder>;
  paymentAssetCode?: Maybe<SortOrderInput>;
  status?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrderInput>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FindManyInvoiceOutput = {
  id: Scalars['String'];
  companyId: Scalars['String'];
  invoiceAssetCode: Scalars['String'];
  invoiceAmount: Scalars['Decimal'];
  paymentAssetCode?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Decimal']>;
  paymentFee?: Maybe<Scalars['Decimal']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paidAmount: Scalars['Decimal'];
  paidFee: Scalars['Decimal'];
  status: InvoiceStatus;
  externalOrderId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productCategory?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
  creatorId: Scalars['String'];
  isCreatedByApi: Scalars['Boolean'];
  isPaymentAfterTimeout: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  rate?: Maybe<Scalars['Decimal']>;
  rateFreezeAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  Address?: Maybe<DepositAddress>;
  InvoiceAsset: PublicAssetOutput;
  PaymentAsset?: Maybe<PublicAssetOutput>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Creator: InvoiceCreatorOutput;
  CompletedByUser?: Maybe<InvoiceCompletedByUserOutput>;
  AutoConvertSettings?: Maybe<InvoiceAutoConvertSettingsOutput>;
  Transfers?: Maybe<Array<PublicTransferWithAmlDetailsOutput>>;
  /** The product used to calculate the invoice payment amount */
  Product?: Maybe<PublicProductOutput>;
};

export type FindManyInvoiceWhereInput = {
  id?: Maybe<StringFilter>;
  invoiceAssetCode?: Maybe<StringFilter>;
  paymentAssetCode?: Maybe<StringNullableFilter>;
  expireAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  externalOrderId?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<FindManyInvoiceWhereInput>>;
  OR?: Maybe<Array<FindManyInvoiceWhereInput>>;
  NOT?: Maybe<Array<FindManyInvoiceWhereInput>>;
};

export type FindManyPayoutOperationByPayoutOrderByInput = {
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type FindManyPayoutOperationByPayoutWhereInput = {
  status?: Maybe<EnumPayoutOperationStatusFilter>;
  payoutId: Scalars['String'];
};

export type FindManyPayoutOperationOutput = {
  id: Scalars['String'];
  originalAmount: Scalars['Decimal'];
  publicReason: Array<Scalars['String']>;
  status: PayoutOperationStatus;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  Transfer?: Maybe<PublicTransferOutput>;
  PaymentMethod: PublicPaymentMethodWithAssetOutput;
  PaymentDetails: PayoutOperationPaymentDetailsOutput;
};

export type FindManyPublicAssetWhereInput = {
  code?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  description?: Maybe<StringFilter>;
  position?: Maybe<IntFilter>;
  precision?: Maybe<IntFilter>;
  type?: Maybe<EnumAssetTypeFilter>;
  rateFreezeSeconds?: Maybe<IntFilter>;
  minInvoiceAmount?: Maybe<DecimalFilter>;
  isInvoiceCurrency?: Maybe<BoolFilter>;
  isPaymentCurrency?: Maybe<BoolFilter>;
  isReferenceCurrency?: Maybe<BoolFilter>;
  isAutoConvertCurrency?: Maybe<BoolFilter>;
  isReferralPayoutCurrency?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  AND?: Maybe<Array<FindManyPublicAssetWhereInput>>;
  OR?: Maybe<Array<FindManyPublicAssetWhereInput>>;
  NOT?: Maybe<Array<FindManyPublicAssetWhereInput>>;
};

export type FindManyReportOrderByInput = {
  id?: Maybe<SortOrder>;
  expireAt?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrderInput>;
};

export type FindManyReportWhereInput = {
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumReportTypeFilter>;
  status?: Maybe<EnumReportStatusFilter>;
  expireAt?: Maybe<DateTimeFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  AND?: Maybe<Array<FindManyReportWhereInput>>;
  OR?: Maybe<Array<FindManyReportWhereInput>>;
  NOT?: Maybe<Array<FindManyReportWhereInput>>;
};

export type FindNotificationsWhereInput = {
  id?: Maybe<StringFilter>;
  group?: Maybe<StringFilter>;
  isRead?: Maybe<BoolFilter>;
  entityId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type FindRelatedCompaniesOutput = {
  id: Scalars['String'];
  title: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  type: CompanyType;
  identificationStatus: IdentificationStatus;
  role: Scalars['String'];
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type GenerateKeysOutput = {
  /** Generated api key used for api requests */
  apiKey: Scalars['String'];
  /** Generated publicKey used for verify api request */
  publicKey: Scalars['String'];
  /** Generated privateKey used for sign api request */
  privateKey: Scalars['String'];
};

export type GenerateOtpUriOutput = {
  /** Uri for generate OTP QR code */
  uri: Scalars['String'];
  /** Secret for OTP */
  secret: Scalars['String'];
  /** Needed only for type sharing to FE */
  otpExceptionType?: Maybe<OtpExceptionType>;
};

export type GetAccountLinkStatus = {
  status?: Maybe<ZenStatus>;
  reason?: Maybe<Scalars['String']>;
  businessAccountConfirmationUrl?: Maybe<Scalars['String']>;
};

export type GroupedByAssetAutoConvertPaymentMethodPublicOutput = {
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  position: Scalars['Int'];
  precision: Scalars['Int'];
  payload: Scalars['JSON'];
  type: AssetType;
  rateFreezeSeconds: Scalars['Int'];
  minInvoiceAmount: Scalars['Decimal'];
  isInvoiceCurrency: Scalars['Boolean'];
  isPaymentCurrency: Scalars['Boolean'];
  isReferenceCurrency: Scalars['Boolean'];
  isAutoConvertCurrency: Scalars['Boolean'];
  isReferralPayoutCurrency: Scalars['Boolean'];
  PaymentMethods: Array<AutoConvertPaymentMethodPublicOutput>;
};

export type GroupedByAssetPaymentMethodWithProductPublicOutput = {
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  position: Scalars['Int'];
  precision: Scalars['Int'];
  payload: Scalars['JSON'];
  type: AssetType;
  rateFreezeSeconds: Scalars['Int'];
  minInvoiceAmount: Scalars['Decimal'];
  isInvoiceCurrency: Scalars['Boolean'];
  isPaymentCurrency: Scalars['Boolean'];
  isReferenceCurrency: Scalars['Boolean'];
  isAutoConvertCurrency: Scalars['Boolean'];
  isReferralPayoutCurrency: Scalars['Boolean'];
  PaymentMethods: Array<PublicPaymentMethodOutput>;
};

export enum IdentificationStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Created = 'CREATED',
  NotVerified = 'NOT_VERIFIED'
}

export type InitiatorApiKeyOutput = {
  /** API key ID */
  id: Scalars['String'];
  /** API key name */
  name: Scalars['String'];
};

export type InitiatorProfileDetailsOutput = {
  id: Scalars['String'];
  sn: Scalars['Int'];
  username: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  fullName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identificationStatus: IdentificationStatus;
  merchantId: Scalars['String'];
  role: Scalars['String'];
  isSuspicious: Scalars['Boolean'];
  isKUNAEmployee: Scalars['Boolean'];
  isAgreePrivacyPolicy: Scalars['Boolean'];
  status: StatusOfUser;
  utm: Scalars['String'];
  affiliateId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  /** @deprecated Will be removed */
  email: Scalars['String'];
  /** Contain FirstName + LastName or username */
  computedFullName: Scalars['String'];
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type InvitedCoworkerListRelationFilter = {
  every?: Maybe<InvitedCoworkerWhereInput>;
  some?: Maybe<InvitedCoworkerWhereInput>;
  none?: Maybe<InvitedCoworkerWhereInput>;
};

export type InvitedCoworkerSecretData = {
  email?: Maybe<Scalars['String']>;
};

export type InvitedCoworkerWhereInput = {
  AND?: Maybe<Array<InvitedCoworkerWhereInput>>;
  OR?: Maybe<Array<InvitedCoworkerWhereInput>>;
  NOT?: Maybe<Array<InvitedCoworkerWhereInput>>;
  id?: Maybe<StringFilter>;
  emailHash?: Maybe<StringFilter>;
  role?: Maybe<StringFilter>;
  companyId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  Company?: Maybe<CompanyRelationFilter>;
};

export type InvoiceAutoConvertSettingsOutput = {
  currency: Scalars['String'];
  volatilityCoverageEnabled: Scalars['Boolean'];
};

export type InvoiceCalculationDetailsOutput = {
  raw: InvoiceCalculationRawDetailsOutput;
  withCoefficient: InvoiceCalculationWithCoefficientDetailsOutput;
  difference: Scalars['Decimal'];
  coefficientApplied: Scalars['Boolean'];
};

export type InvoiceCalculationRawDetailsOutput = {
  rate: Scalars['Decimal'];
  amount: Scalars['Decimal'];
};

export type InvoiceCalculationWithCoefficientDetailsOutput = {
  rate: Scalars['Decimal'];
  amount: Scalars['Decimal'];
  coefficient: Scalars['Decimal'];
};

export type InvoiceCompletedByUserOutput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type InvoiceCreatorOutput = {
  id: Scalars['String'];
  /** Username or API key name */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Will be removed */
  email?: Maybe<Scalars['String']>;
  type: InvoiceCreatorType;
};

export enum InvoiceCreatorType {
  User = 'USER',
  ApiKey = 'API_KEY'
}

export type InvoiceCustomizationOutput = {
  redirect?: Maybe<InvoiceRedirectCustomizationOutput>;
};

export type InvoiceDetailsOutput = {
  id: Scalars['String'];
  companyId: Scalars['String'];
  invoiceAssetCode: Scalars['String'];
  invoiceAmount: Scalars['Decimal'];
  paymentAssetCode?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Decimal']>;
  paymentFee?: Maybe<Scalars['Decimal']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paidAmount: Scalars['Decimal'];
  paidFee: Scalars['Decimal'];
  status: InvoiceStatus;
  externalOrderId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productCategory?: Maybe<Scalars['String']>;
  addressId?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
  creatorId: Scalars['String'];
  isCreatedByApi: Scalars['Boolean'];
  isPaymentAfterTimeout: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  rate?: Maybe<Scalars['Decimal']>;
  rateFreezeAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  Address?: Maybe<DepositAddress>;
  InvoiceAsset: PublicAssetOutput;
  PaymentAsset?: Maybe<PublicAssetOutput>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Creator: InvoiceCreatorOutput;
  CompletedByUser?: Maybe<InvoiceCompletedByUserOutput>;
  AutoConvertSettings?: Maybe<InvoiceAutoConvertSettingsOutput>;
  Transfers?: Maybe<Array<PublicTransferWithAmlDetailsOutput>>;
  /** The product used to calculate the invoice payment amount */
  Product?: Maybe<PublicProductOutput>;
  CalculationDetails?: Maybe<InvoiceCalculationDetailsOutput>;
  PaymentDetails: InvoicePaymentDetailsOutput;
  Customization?: Maybe<InvoiceCustomizationOutput>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
};

export type InvoiceNullableRelationFilter = {
  is?: Maybe<InvoiceWhereInput>;
  isNot?: Maybe<InvoiceWhereInput>;
};

export type InvoicePaymentDetailsOutput = {
  paidAmount: Scalars['Decimal'];
  paidFee: Scalars['Decimal'];
  leftAmount: Scalars['Decimal'];
  paidAmountInInvoiceAsset: Scalars['Decimal'];
  leftAmountInInvoiceAsset: Scalars['Decimal'];
};

export type InvoicePaymentLinkOutput = {
  paymentLink: Scalars['String'];
};

export type InvoiceRedirectCustomizationOutput = {
  url?: Maybe<Scalars['String']>;
  buttonTitle?: Maybe<Scalars['String']>;
};

export enum InvoiceStatus {
  Created = 'CREATED',
  PaymentAwaiting = 'PAYMENT_AWAITING',
  ConfirmationAwaiting = 'CONFIRMATION_AWAITING',
  LimitsOutOfRange = 'LIMITS_OUT_OF_RANGE',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Timeout = 'TIMEOUT',
  Deactivated = 'DEACTIVATED',
  Declined = 'DECLINED',
  Suspended = 'SUSPENDED',
  Arrested = 'ARRESTED',
  CurrencyMismatch = 'CURRENCY_MISMATCH'
}

export type InvoiceWhereInput = {
  AND?: Maybe<Array<InvoiceWhereInput>>;
  OR?: Maybe<Array<InvoiceWhereInput>>;
  NOT?: Maybe<Array<InvoiceWhereInput>>;
  id?: Maybe<StringFilter>;
  companyId?: Maybe<StringFilter>;
  invoiceAssetCode?: Maybe<StringFilter>;
  invoiceAmount?: Maybe<DecimalFilter>;
  paymentAssetCode?: Maybe<StringNullableFilter>;
  paymentAmount?: Maybe<DecimalNullableFilter>;
  paymentFee?: Maybe<DecimalNullableFilter>;
  expireAt?: Maybe<DateTimeNullableFilter>;
  paymentMethodId?: Maybe<StringNullableFilter>;
  paidAmount?: Maybe<DecimalFilter>;
  paidFee?: Maybe<DecimalFilter>;
  status?: Maybe<EnumInvoiceStatusFilter>;
  payload?: Maybe<JsonFilter>;
  externalOrderId?: Maybe<StringNullableFilter>;
  productDescription?: Maybe<StringNullableFilter>;
  productCategory?: Maybe<StringNullableFilter>;
  addressId?: Maybe<StringNullableFilter>;
  completedByUserId?: Maybe<StringNullableFilter>;
  creatorId?: Maybe<StringFilter>;
  isCreatedByApi?: Maybe<BoolFilter>;
  isPaymentAfterTimeout?: Maybe<BoolFilter>;
  merchantId?: Maybe<StringFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  rate?: Maybe<DecimalNullableFilter>;
  rateFreezeAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  InvoiceAsset?: Maybe<AssetRelationFilter>;
  PaymentAsset?: Maybe<AssetNullableRelationFilter>;
  Company?: Maybe<CompanyRelationFilter>;
  Address?: Maybe<DepositAddressNullableRelationFilter>;
  PaymentMethod?: Maybe<PaymentMethodNullableRelationFilter>;
  CompletedByUser?: Maybe<ProfileNullableRelationFilter>;
  Transfers?: Maybe<TransferListRelationFilter>;
};


export type JsonFilter = {
  equals?: Maybe<Scalars['JSON']>;
  path?: Maybe<Array<Scalars['String']>>;
  string_contains?: Maybe<Scalars['String']>;
  string_starts_with?: Maybe<Scalars['String']>;
  string_ends_with?: Maybe<Scalars['String']>;
  array_contains?: Maybe<Scalars['JSON']>;
  array_starts_with?: Maybe<Scalars['JSON']>;
  array_ends_with?: Maybe<Scalars['JSON']>;
  lt?: Maybe<Scalars['JSON']>;
  lte?: Maybe<Scalars['JSON']>;
  gt?: Maybe<Scalars['JSON']>;
  gte?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type Language = {
  code: Scalars['String'];
  label: Scalars['String'];
};

export type LaunchDonationProcessForNonAuthOutput = {
  id: Scalars['String'];
  address: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
};

export type LaunchPayoutOutput = {
  success: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  issues?: Maybe<Array<PayoutIssueOutput>>;
};

export type MaintenanceNotificationOutput = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  type: MaintenanceNotificationType;
  fromTime: Scalars['DateTime'];
  tillTime?: Maybe<Scalars['DateTime']>;
  payload?: Maybe<Scalars['JSON']>;
  message: Scalars['String'];
};

export enum MaintenanceNotificationType {
  Prevent = 'Prevent',
  Actual = 'Actual'
}

export type Mutation = {
  setRemoteConfig: RemoteConfig;
  reset: Array<RemoteConfig>;
  setRabacSchema: RabacSchema;
  freshDeskAuth: AuthenticateFreshDeskOutput;
  disableSession: SuccessOutput;
  /** Add a new API key to the company */
  addApiKey: SuccessOutput;
  /** Set new company apiKey ip white list */
  setApiKeyIPWhiteList: SuccessOutput;
  /** Disable active company api key */
  disableApiKey: SuccessOutput;
  /** Delete single company api key */
  deleteCompanyApiKey: SuccessOutput;
  /** Delete all company api keys */
  deleteAllCompanyApiKeys: SuccessOutput;
  register: AuthTokensOutput;
  /** Send email with reset password token, use it if forgot or wanna change password */
  requestResetPassword: SuccessOutput;
  /** Reset password(use reset token from email) */
  resetPassword: SuccessOutput;
  /** Confirm user email */
  confirmEmail: SuccessOutput;
  /** Send confirmation email again */
  resendConfirmationEmail: SuccessOutput;
  /** Register throughout google id */
  googleRegister: AuthTokensOutput;
  /** Register throughout kuna id */
  kunaRegister: AuthTokensOutput;
  /** Add new otp */
  enableOtp: SuccessOutput;
  /** Disable active otp */
  disableOtp: SuccessOutput;
  /** Invalidates all user's active sessions. Allows to exclude the current */
  invalidateSessions: SuccessOutput;
  /** Invalidates specified session for the user */
  invalidateSessionByKey: SuccessOutput;
  zenCreateAccountLink: SuccessOutput;
  zenCreateBusinessAccountLink: CreateBusinessAccountLink;
  zenResendSms: SuccessOutput;
  zenUnlinkAccount: SuccessOutput;
  zenCreateAccountRegistrationRequest: SuccessOutput;
  startSumsubIdentification: StartSumSubIdentificationOutput;
  updateProfile: UserProfileOutput;
  /** Toggling email notification for user and not tied to any specific company (for example login) */
  setUserNotificationSettings: SuccessOutput;
  /** Toggle email notifications on a per-company basis, rather than for individual users (for example withdraw/deposit) */
  setNotificationByCompanySettings: SuccessOutput;
  updateUserNotificationSettings: SuccessOutput;
  updateCompanyNotificationSettings: SuccessOutput;
  /** Create new company */
  createOneCompany: CompanyIdOutput;
  /** Update current company(CompanyId takes from Jwt token) */
  updateCompany: SuccessOutput;
  /** Toggle auto convert for company */
  toggleAutoConvertForCompany: SuccessOutput;
  updateOneCompanyRelation: SuccessOutput;
  disableCompanyRelation: SuccessOutput;
  transferCompanyOwnership: SuccessOutput;
  /** Add asset settings for the company */
  bulkUpsertCompanyAssetSettings: SuccessOutput;
  deleteCoworkerInvitation: SuccessOutput;
  /** Invite new coworker to company */
  inviteCoworkerToCompany: SuccessOutput;
  kunaProMigration: SuccessOutput;
  /** Enable/Disable email confirmation for entity(for example withdraw) */
  setConfirmationSetting: SuccessOutput;
  /** Create constant deposit address for source(blockchain). Allowed 1 time for 1 source */
  createConstDepositAddress: DepositAddressPublicOutput;
  createInvoice: CreateInvoiceOutput;
  deactivateInvoice: SuccessOutput;
  completeInvoiceManually: SuccessOutput;
  preRequestPublicInvoice: PreRequestInvoiceOutput;
  executePublicInvoice: ExecuteInvoiceOutput;
  sendInvoiceReceiptToBuyer: SuccessOutput;
  startConvertManuallyForInvoice: SuccessOutput;
  updateCustomQuestionnaireField: SuccessOutput;
  saveAnswersToQuestionnaireForNonAuth: SuccessOutput;
  calculateCompanyLimitForWithdrawal: CalculatedCompanyLimitOutput;
  createOneWithdraw: SuccessOutput;
  createWithdrawAll: SuccessOutput;
  updateOneWithdraw: SuccessOutput;
  updateOneMyOwnWithdraw: SuccessOutput;
  /** Delete payment requisite */
  deleteOneRequisite: SuccessOutput;
  /** Delete payment requisite */
  deleteManyRequisite: SuccessOutput;
  /** Create payment requisite */
  createOneRequisite: Requisite;
  /** Update payment requisite */
  updateOneRequisite: Requisite;
  generateStatementReport: SuccessOutput;
  generatePayoutReport: CreatedReportOutput;
  generateInvoiceReport: CreatedReportOutput;
  generateTransactionReport: CreatedTransactionReportOutput;
  generateReportOnMyOwnTransactions: CreatedTransactionReportOutput;
  generateTransactionReportByWorker: SuccessOutput;
  generateReportOnMyOwnTransactionsByWorker: SuccessOutput;
  generateReportOnMyOwnInvoices: CreatedTransactionReportOutput;
  emitTestDeposit: SuccessOutput;
  launchDonationProcessForNonAuth: LaunchDonationProcessForNonAuthOutput;
  /** Create payout to many contractors */
  createPayout: CreatePayoutOutput;
  /** Launch created Payout */
  launchPayout: LaunchPayoutOutput;
  createAndLaunchPayout: LaunchPayoutOutput;
  /** Checks whether it is possible to perform a payout */
  preRequestPayout: PreRequestPayoutOutput;
  /** Update payout */
  updatePayout: SuccessOutput;
  /** Retry failed payout operation */
  retryProcessPayoutOperation: SuccessOutput;
  /** Update payout operation before payout launch */
  updatePayoutOperationDetails: SuccessOutput;
  /** Delete payout operation before payout launch */
  deletePayoutOperation: SuccessOutput;
  publicRateUX: SuccessOutput;
  publicCollectGenericFeedback: SuccessOutput;
  publicGetPresignUrlsForFeedbackAttachments: PresignFeedbackAttachmentUrlsOutput;
};


export type MutationSetRemoteConfigArgs = {
  data: SetRemoteConfigInput;
};


export type MutationSetRabacSchemaArgs = {
  data: SetRabacSchemaMutationInput;
};


export type MutationFreshDeskAuthArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationDisableSessionArgs = {
  fingerprint: Scalars['String'];
};


export type MutationAddApiKeyArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  publicKey: Scalars['String'];
  privateKey: Scalars['String'];
  apiKey: Scalars['String'];
  IPwhiteList?: Maybe<Array<Scalars['String']>>;
  allowedOperations?: Maybe<Array<CompanyAllowedOperationEnum>>;
};


export type MutationSetApiKeyIpWhiteListArgs = {
  id: Scalars['String'];
  IPwhiteList?: Maybe<Array<Scalars['String']>>;
};


export type MutationDisableApiKeyArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCompanyApiKeyArgs = {
  apiKeyId: Scalars['String'];
};


export type MutationRegisterArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
  utm?: Maybe<Scalars['String']>;
  isSubscribe?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['String']>;
  isAgreePrivacyPolicy?: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};


export type MutationRequestResetPasswordArgs = {
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  resetToken: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationConfirmEmailArgs = {
  confirmToken: Scalars['String'];
};


export type MutationGoogleRegisterArgs = {
  token?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  utm?: Maybe<Scalars['String']>;
  isSubscribe?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['String']>;
  isAgreePrivacyPolicy?: Maybe<Scalars['Boolean']>;
};


export type MutationKunaRegisterArgs = {
  token: Scalars['String'];
  utm?: Maybe<Scalars['String']>;
  isSubscribe?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['String']>;
  isAgreePrivacyPolicy?: Maybe<Scalars['Boolean']>;
};


export type MutationEnableOtpArgs = {
  otp: Scalars['String'];
};


export type MutationDisableOtpArgs = {
  otp: Scalars['String'];
};


export type MutationInvalidateSessionsArgs = {
  invalidateCurrent?: Maybe<Scalars['Boolean']>;
};


export type MutationInvalidateSessionByKeyArgs = {
  key: Scalars['String'];
};


export type MutationZenCreateAccountLinkArgs = {
  phone: Scalars['String'];
};


export type MutationZenCreateBusinessAccountLinkArgs = {
  phone: Scalars['String'];
};


export type MutationStartSumsubIdentificationArgs = {
  completedLevel?: Maybe<Scalars['String']>;
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};


export type MutationSetUserNotificationSettingsArgs = {
  data: Array<NotificationInput>;
};


export type MutationSetNotificationByCompanySettingsArgs = {
  data: Array<NotificationByCompanyInput>;
};


export type MutationUpdateUserNotificationSettingsArgs = {
  data: Array<UpdateUserNotificationSettingsInput>;
};


export type MutationUpdateCompanyNotificationSettingsArgs = {
  data: Array<UpdateCompanyNotificationSettingsInput>;
};


export type MutationCreateOneCompanyArgs = {
  data: CreateOneCompanyInput;
};


export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
};


export type MutationToggleAutoConvertForCompanyArgs = {
  asset?: Maybe<Scalars['String']>;
  volatilityCoverageEnabled?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateOneCompanyRelationArgs = {
  data: UpdateCompanyRelationInput;
  where: CompanyUserUniqueInput;
};


export type MutationDisableCompanyRelationArgs = {
  where: CompanyUserUniqueInput;
};


export type MutationTransferCompanyOwnershipArgs = {
  newOwnerUserId: Scalars['String'];
};


export type MutationBulkUpsertCompanyAssetSettingsArgs = {
  data: Array<UpsertOneCompanyAssetSettingsInput>;
};


export type MutationDeleteCoworkerInvitationArgs = {
  id: Scalars['String'];
};


export type MutationInviteCoworkerToCompanyArgs = {
  email: Scalars['String'];
  role: Scalars['String'];
};


export type MutationSetConfirmationSettingArgs = {
  data: Array<ConfirmationInput>;
};


export type MutationCreateConstDepositAddressArgs = {
  id: Scalars['String'];
  source: Scalars['String'];
};


export type MutationCreateInvoiceArgs = {
  id?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  asset: Scalars['String'];
  externalOrderId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productCategory?: Maybe<Scalars['String']>;
  isCreatedByApi?: Maybe<Scalars['Boolean']>;
  callbackUrl?: Maybe<Scalars['String']>;
  shouldSendTransactionalCallback?: Maybe<Scalars['Boolean']>;
  customization?: Maybe<CreateInvoiceCustomizationInput>;
  questionnaire?: Maybe<CreateQuestionnaireInput>;
};


export type MutationDeactivateInvoiceArgs = {
  id: Scalars['String'];
};


export type MutationCompleteInvoiceManuallyArgs = {
  id: Scalars['String'];
  launchConvert?: Maybe<Scalars['Boolean']>;
};


export type MutationPreRequestPublicInvoiceArgs = {
  id: Scalars['String'];
  paymentMethodCode: Scalars['String'];
  paymentAsset: Scalars['String'];
};


export type MutationExecutePublicInvoiceArgs = {
  id: Scalars['String'];
};


export type MutationSendInvoiceReceiptToBuyerArgs = {
  id: Scalars['String'];
  email: Scalars['String'];
  language?: Maybe<Scalars['String']>;
};


export type MutationStartConvertManuallyForInvoiceArgs = {
  id: Scalars['String'];
  toCurrency?: Maybe<Scalars['String']>;
  acceptedPartiallyConvert?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateCustomQuestionnaireFieldArgs = {
  customFieldLabel?: Maybe<Scalars['String']>;
};


export type MutationSaveAnswersToQuestionnaireForNonAuthArgs = {
  id: Scalars['String'];
  answers: Scalars['JSON'];
};


export type MutationCreateOneWithdrawArgs = {
  id: Scalars['String'];
  amount: Scalars['String'];
  asset: Scalars['String'];
  paymentMethod: Scalars['String'];
  fields?: Maybe<Scalars['JSON']>;
  comment?: Maybe<Scalars['String']>;
  callbackUrl?: Maybe<Scalars['String']>;
  callbackId?: Maybe<Scalars['String']>;
  requisiteId?: Maybe<Scalars['String']>;
  saveRequisiteName?: Maybe<Scalars['String']>;
};


export type MutationCreateWithdrawAllArgs = {
  amount: Scalars['String'];
  asset: Scalars['String'];
  paymentMethod: Scalars['String'];
  fields?: Maybe<Scalars['JSON']>;
  comment?: Maybe<Scalars['String']>;
  callbackUrl?: Maybe<Scalars['String']>;
  callbackId?: Maybe<Scalars['String']>;
  requisiteId?: Maybe<Scalars['String']>;
  saveRequisiteName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationUpdateOneWithdrawArgs = {
  where: RecordIdInput;
  data: UpdateOneWithdrawDataInput;
};


export type MutationUpdateOneMyOwnWithdrawArgs = {
  where: RecordIdInput;
  data: UpdateOneWithdrawDataInput;
};


export type MutationDeleteOneRequisiteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteManyRequisiteArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationCreateOneRequisiteArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  currency: Scalars['String'];
  source: Scalars['String'];
  type: RequisiteType;
  values: Scalars['JSON'];
};


export type MutationUpdateOneRequisiteArgs = {
  id: Scalars['String'];
  paymentMethodAsset: Scalars['String'];
  paymentMethodCode: Scalars['String'];
  paymentMethodTransferType: TransferType;
  name: Scalars['String'];
  fields: Scalars['JSON'];
};


export type MutationGenerateStatementReportArgs = {
  dateRange: DateRangeInput;
  format: ReportFileFormat;
  timeZone?: Maybe<Scalars['String']>;
};


export type MutationGeneratePayoutReportArgs = {
  payoutId: Scalars['String'];
  settings?: Maybe<CreateReportSettings>;
};


export type MutationGenerateInvoiceReportArgs = {
  where: CreateInvoiceReportWhereInput;
  settings?: Maybe<CreateReportSettings>;
};


export type MutationGenerateTransactionReportArgs = {
  where: CreateReportWhereInput;
  settings?: Maybe<CreateReportSettings>;
};


export type MutationGenerateReportOnMyOwnTransactionsArgs = {
  where: CreateReportWhereInput;
  settings?: Maybe<CreateReportSettings>;
};


export type MutationGenerateTransactionReportByWorkerArgs = {
  where: CreateReportWhereInput;
  settings?: Maybe<CreateReportSettings>;
};


export type MutationGenerateReportOnMyOwnTransactionsByWorkerArgs = {
  where: CreateReportWhereInput;
  settings?: Maybe<CreateReportSettings>;
};


export type MutationGenerateReportOnMyOwnInvoicesArgs = {
  where: CreateInvoiceReportWhereInput;
  settings?: Maybe<CreateReportSettings>;
};


export type MutationEmitTestDepositArgs = {
  asset: Scalars['String'];
  amount: Scalars['String'];
};


export type MutationLaunchDonationProcessForNonAuthArgs = {
  companyId: Scalars['String'];
  paymentMethodId: Scalars['String'];
};


export type MutationCreatePayoutArgs = {
  id?: Maybe<Scalars['String']>;
  contractors: Array<CreatePayoutContractorInput>;
  externalId?: Maybe<Scalars['String']>;
  CallbackSettings?: Maybe<PayoutCallbackSettingsInput>;
};


export type MutationLaunchPayoutArgs = {
  id: Scalars['String'];
};


export type MutationCreateAndLaunchPayoutArgs = {
  id?: Maybe<Scalars['String']>;
  contractors: Array<CreatePayoutContractorInput>;
  externalId?: Maybe<Scalars['String']>;
  CallbackSettings?: Maybe<PayoutCallbackSettingsInput>;
};


export type MutationPreRequestPayoutArgs = {
  contractors?: Maybe<Array<CreatePayoutContractorInput>>;
  payoutId?: Maybe<Scalars['String']>;
};


export type MutationUpdatePayoutArgs = {
  where: UpdatePayoutWhereInput;
  data: UpdatePayoutDataInput;
};


export type MutationRetryProcessPayoutOperationArgs = {
  payoutOperationId: Scalars['String'];
};


export type MutationUpdatePayoutOperationDetailsArgs = {
  id: Scalars['String'];
  data: UpdatePayoutOperationDetailsDataInput;
};


export type MutationDeletePayoutOperationArgs = {
  id: Scalars['String'];
};


export type MutationPublicRateUxArgs = {
  feature: Scalars['String'];
  rating: Scalars['String'];
  feedback?: Maybe<Scalars['String']>;
  meta?: Maybe<FeedbackMeta>;
};


export type MutationPublicCollectGenericFeedbackArgs = {
  feedback: Scalars['String'];
  attachments?: Maybe<Array<Scalars['String']>>;
  meta?: Maybe<FeedbackMeta>;
};


export type MutationPublicGetPresignUrlsForFeedbackAttachmentsArgs = {
  feedbackId: Scalars['String'];
  previews: Array<PresignFeedbackAttachmentUrlInput>;
};

export type NestedBigIntFilter = {
  equals?: Maybe<Scalars['BigInt']>;
  in?: Maybe<Array<Scalars['BigInt']>>;
  notIn?: Maybe<Array<Scalars['BigInt']>>;
  lt?: Maybe<Scalars['BigInt']>;
  lte?: Maybe<Scalars['BigInt']>;
  gt?: Maybe<Scalars['BigInt']>;
  gte?: Maybe<Scalars['BigInt']>;
  not?: Maybe<NestedBigIntFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDecimalFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalFilter>;
};

export type NestedDecimalNullableFilter = {
  equals?: Maybe<Scalars['Decimal']>;
  in?: Maybe<Array<Scalars['Decimal']>>;
  notIn?: Maybe<Array<Scalars['Decimal']>>;
  lt?: Maybe<Scalars['Decimal']>;
  lte?: Maybe<Scalars['Decimal']>;
  gt?: Maybe<Scalars['Decimal']>;
  gte?: Maybe<Scalars['Decimal']>;
  not?: Maybe<NestedDecimalNullableFilter>;
};

export type NestedEnumAssetTypeFilter = {
  equals?: Maybe<AssetType>;
  in?: Maybe<Array<AssetType>>;
  notIn?: Maybe<Array<AssetType>>;
  not?: Maybe<NestedEnumAssetTypeFilter>;
};

export type NestedEnumCompanyKycSourceFilter = {
  equals?: Maybe<CompanyKycSource>;
  in?: Maybe<Array<CompanyKycSource>>;
  notIn?: Maybe<Array<CompanyKycSource>>;
  not?: Maybe<NestedEnumCompanyKycSourceFilter>;
};

export type NestedEnumCompanyLimitPeriodFilter = {
  equals?: Maybe<CompanyLimitPeriod>;
  in?: Maybe<Array<CompanyLimitPeriod>>;
  notIn?: Maybe<Array<CompanyLimitPeriod>>;
  not?: Maybe<NestedEnumCompanyLimitPeriodFilter>;
};

export type NestedEnumCompanyLimitTypeFilter = {
  equals?: Maybe<CompanyLimitType>;
  in?: Maybe<Array<CompanyLimitType>>;
  notIn?: Maybe<Array<CompanyLimitType>>;
  not?: Maybe<NestedEnumCompanyLimitTypeFilter>;
};

export type NestedEnumCompanyTypeFilter = {
  equals?: Maybe<CompanyType>;
  in?: Maybe<Array<CompanyType>>;
  notIn?: Maybe<Array<CompanyType>>;
  not?: Maybe<NestedEnumCompanyTypeFilter>;
};

export type NestedEnumConvertStatusFilter = {
  equals?: Maybe<ConvertStatus>;
  in?: Maybe<Array<ConvertStatus>>;
  notIn?: Maybe<Array<ConvertStatus>>;
  not?: Maybe<NestedEnumConvertStatusFilter>;
};

export type NestedEnumConvertSupportStatusFilter = {
  equals?: Maybe<ConvertSupportStatus>;
  in?: Maybe<Array<ConvertSupportStatus>>;
  notIn?: Maybe<Array<ConvertSupportStatus>>;
  not?: Maybe<NestedEnumConvertSupportStatusFilter>;
};

export type NestedEnumDepositAddressTypeFilter = {
  equals?: Maybe<DepositAddressType>;
  in?: Maybe<Array<DepositAddressType>>;
  notIn?: Maybe<Array<DepositAddressType>>;
  not?: Maybe<NestedEnumDepositAddressTypeFilter>;
};

export type NestedEnumIdentificationStatusFilter = {
  equals?: Maybe<IdentificationStatus>;
  in?: Maybe<Array<IdentificationStatus>>;
  notIn?: Maybe<Array<IdentificationStatus>>;
  not?: Maybe<NestedEnumIdentificationStatusFilter>;
};

export type NestedEnumInvoiceStatusFilter = {
  equals?: Maybe<InvoiceStatus>;
  in?: Maybe<Array<InvoiceStatus>>;
  notIn?: Maybe<Array<InvoiceStatus>>;
  not?: Maybe<NestedEnumInvoiceStatusFilter>;
};

export type NestedEnumPayoutOperationStatusFilter = {
  equals?: Maybe<PayoutOperationStatus>;
  in?: Maybe<Array<PayoutOperationStatus>>;
  notIn?: Maybe<Array<PayoutOperationStatus>>;
  not?: Maybe<NestedEnumPayoutOperationStatusFilter>;
};

export type NestedEnumPayoutStatusFilter = {
  equals?: Maybe<PayoutStatus>;
  in?: Maybe<Array<PayoutStatus>>;
  notIn?: Maybe<Array<PayoutStatus>>;
  not?: Maybe<NestedEnumPayoutStatusFilter>;
};

export type NestedEnumPayoutSupportStatusFilter = {
  equals?: Maybe<PayoutSupportStatus>;
  in?: Maybe<Array<PayoutSupportStatus>>;
  notIn?: Maybe<Array<PayoutSupportStatus>>;
  not?: Maybe<NestedEnumPayoutSupportStatusFilter>;
};

export type NestedEnumReportStatusFilter = {
  equals?: Maybe<ReportStatus>;
  in?: Maybe<Array<ReportStatus>>;
  notIn?: Maybe<Array<ReportStatus>>;
  not?: Maybe<NestedEnumReportStatusFilter>;
};

export type NestedEnumReportTypeFilter = {
  equals?: Maybe<ReportType>;
  in?: Maybe<Array<ReportType>>;
  notIn?: Maybe<Array<ReportType>>;
  not?: Maybe<NestedEnumReportTypeFilter>;
};

export type NestedEnumRequisiteTypeFilter = {
  equals?: Maybe<RequisiteType>;
  in?: Maybe<Array<RequisiteType>>;
  notIn?: Maybe<Array<RequisiteType>>;
  not?: Maybe<NestedEnumRequisiteTypeFilter>;
};

export type NestedEnumStatusOfCompanyFilter = {
  equals?: Maybe<StatusOfCompany>;
  in?: Maybe<Array<StatusOfCompany>>;
  notIn?: Maybe<Array<StatusOfCompany>>;
  not?: Maybe<NestedEnumStatusOfCompanyFilter>;
};

export type NestedEnumStatusOfUserFilter = {
  equals?: Maybe<StatusOfUser>;
  in?: Maybe<Array<StatusOfUser>>;
  notIn?: Maybe<Array<StatusOfUser>>;
  not?: Maybe<NestedEnumStatusOfUserFilter>;
};

export type NestedEnumTransferStatusFilter = {
  equals?: Maybe<TransferStatus>;
  in?: Maybe<Array<TransferStatus>>;
  notIn?: Maybe<Array<TransferStatus>>;
  not?: Maybe<NestedEnumTransferStatusFilter>;
};

export type NestedEnumTransferStatusSupportFilter = {
  equals?: Maybe<TransferStatusSupport>;
  in?: Maybe<Array<TransferStatusSupport>>;
  notIn?: Maybe<Array<TransferStatusSupport>>;
  not?: Maybe<NestedEnumTransferStatusSupportFilter>;
};

export type NestedEnumTransferTypeFilter = {
  equals?: Maybe<TransferType>;
  in?: Maybe<Array<TransferType>>;
  notIn?: Maybe<Array<TransferType>>;
  not?: Maybe<NestedEnumTransferTypeFilter>;
};

export type NestedEnumZenStatusFilter = {
  equals?: Maybe<ZenStatus>;
  in?: Maybe<Array<ZenStatus>>;
  notIn?: Maybe<Array<ZenStatus>>;
  not?: Maybe<NestedEnumZenStatusFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NonAuthAmlDetailsOutput = {
  riskScore: Scalars['String'];
};

export type NonAuthCompanyDetailsOutput = {
  id: Scalars['String'];
  title: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  supportInfo?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
};

export type NonAuthDepositAddressOutput = {
  id: Scalars['String'];
  source: Scalars['String'];
  address: Scalars['String'];
  memo?: Maybe<Scalars['String']>;
};

export type NonAuthDepositPaymentMethodOutput = {
  id: Scalars['String'];
  code: Scalars['String'];
  asset: Scalars['String'];
  network?: Maybe<Scalars['String']>;
  processor: Scalars['String'];
  payload: Scalars['JSON'];
  position: Scalars['Int'];
  Asset: PublicAssetOutput;
};

export type NonAuthInvoiceDetailsOutput = {
  id: Scalars['String'];
  companyId: Scalars['String'];
  invoiceAssetCode: Scalars['String'];
  invoiceAmount: Scalars['Decimal'];
  paymentAssetCode?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Decimal']>;
  paymentFee?: Maybe<Scalars['Decimal']>;
  expireAt?: Maybe<Scalars['DateTime']>;
  paymentMethodId?: Maybe<Scalars['String']>;
  paidAmount: Scalars['Decimal'];
  paidFee: Scalars['Decimal'];
  status: InvoiceStatus;
  payload: Scalars['JSON'];
  externalOrderId?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  productCategory?: Maybe<Scalars['String']>;
  completedByUserId?: Maybe<Scalars['String']>;
  creatorId: Scalars['String'];
  isCreatedByApi: Scalars['Boolean'];
  isPaymentAfterTimeout: Scalars['Boolean'];
  completedAt?: Maybe<Scalars['DateTime']>;
  rate?: Maybe<Scalars['Decimal']>;
  rateFreezeAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  InvoiceAsset: PublicAssetOutput;
  PaymentAsset?: Maybe<PublicAssetOutput>;
  Company: NonAuthCompanyDetailsOutput;
  Address?: Maybe<NonAuthDepositAddressOutput>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Transfers?: Maybe<Array<NonAuthTransferDetailsOutput>>;
  /** The product used to calculate the invoice payment amount */
  Product?: Maybe<PublicProductOutput>;
  Customization?: Maybe<InvoiceCustomizationOutput>;
};

export type NonAuthTransferDetailsOutput = {
  id: Scalars['String'];
  status: TransferStatus;
  amount: Scalars['Decimal'];
  processedAmount?: Maybe<Scalars['Decimal']>;
  fee?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  Asset: PublicAssetOutput;
  PaymentMethod?: Maybe<NonAuthDepositPaymentMethodOutput>;
  AmlDetails?: Maybe<NonAuthAmlDetailsOutput>;
  Details?: Maybe<TransferDetailsOutput>;
};

export type NoteListRelationFilter = {
  every?: Maybe<NoteWhereInput>;
  some?: Maybe<NoteWhereInput>;
  none?: Maybe<NoteWhereInput>;
};

export type NoteWhereInput = {
  AND?: Maybe<Array<NoteWhereInput>>;
  OR?: Maybe<Array<NoteWhereInput>>;
  NOT?: Maybe<Array<NoteWhereInput>>;
  id?: Maybe<StringFilter>;
  text?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
  companyId?: Maybe<StringNullableFilter>;
  creatorId?: Maybe<StringFilter>;
  User?: Maybe<ProfileNullableRelationFilter>;
  Company?: Maybe<CompanyNullableRelationFilter>;
  Creator?: Maybe<ProfileRelationFilter>;
};

/** Allowed to enable confirm entities */
export enum NotificationByCompanyEntities {
  AutoConvert = 'autoConvert',
  Deposit = 'deposit',
  Withdraw = 'withdraw',
  Invoice = 'invoice',
  Payout = 'payout',
  ReferralProgram = 'referralProgram'
}

export type NotificationByCompanyInput = {
  entity: NotificationByCompanyEntities;
  isEnabled: Scalars['Boolean'];
};

export type NotificationByCompanySettingsNullableRelationFilter = {
  is?: Maybe<NotificationByCompanySettingsWhereInput>;
  isNot?: Maybe<NotificationByCompanySettingsWhereInput>;
};

export type NotificationByCompanySettingsWhereInput = {
  AND?: Maybe<Array<NotificationByCompanySettingsWhereInput>>;
  OR?: Maybe<Array<NotificationByCompanySettingsWhereInput>>;
  NOT?: Maybe<Array<NotificationByCompanySettingsWhereInput>>;
  id?: Maybe<StringFilter>;
  deposit?: Maybe<BoolFilter>;
  withdraw?: Maybe<BoolFilter>;
  autoConvert?: Maybe<BoolFilter>;
  invoice?: Maybe<BoolFilter>;
  payout?: Maybe<BoolFilter>;
  referralProgram?: Maybe<BoolFilter>;
  userId?: Maybe<StringFilter>;
  companyId?: Maybe<StringFilter>;
  RelationUserToCompany?: Maybe<RelationUserToCompanyRelationFilter>;
};

/** Allowed to enable confirm entities */
export enum NotificationEntities {
  Login = 'login',
  Advertisement = 'advertisement'
}

export type NotificationInput = {
  entity: NotificationEntities;
  isEnabled: Scalars['Boolean'];
};

export type NotificationListRelationFilter = {
  every?: Maybe<NotificationWhereInput>;
  some?: Maybe<NotificationWhereInput>;
  none?: Maybe<NotificationWhereInput>;
};

export type NotificationOutput = {
  id: Scalars['String'];
  userId: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  group: Scalars['String'];
  template: Scalars['String'];
  isRead: Scalars['Boolean'];
  payload: Scalars['JSON'];
  entityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  Asset?: Maybe<PublicAssetOutput>;
};

export type NotificationSettingsByCompanyOutput = {
  deposit: Scalars['Boolean'];
  withdraw: Scalars['Boolean'];
  autoConvert: Scalars['Boolean'];
  invoice: Scalars['Boolean'];
  payout: Scalars['Boolean'];
  referralProgram: Scalars['Boolean'];
  userId: Scalars['String'];
  companyId: Scalars['String'];
};

export type NotificationSettingsDetailsOutput = {
  push: Scalars['Boolean'];
  email: Scalars['Boolean'];
};

export type NotificationSettingsNullableRelationFilter = {
  is?: Maybe<NotificationSettingsWhereInput>;
  isNot?: Maybe<NotificationSettingsWhereInput>;
};

export type NotificationSettingsOutput = {
  login: Scalars['Boolean'];
  advertisement: Scalars['Boolean'];
};

export type NotificationSettingsWhereInput = {
  AND?: Maybe<Array<NotificationSettingsWhereInput>>;
  OR?: Maybe<Array<NotificationSettingsWhereInput>>;
  NOT?: Maybe<Array<NotificationSettingsWhereInput>>;
  id?: Maybe<StringFilter>;
  login?: Maybe<BoolFilter>;
  advertisement?: Maybe<BoolFilter>;
  Profile?: Maybe<ProfileRelationFilter>;
};

export type NotificationWhereInput = {
  AND?: Maybe<Array<NotificationWhereInput>>;
  OR?: Maybe<Array<NotificationWhereInput>>;
  NOT?: Maybe<Array<NotificationWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  companyId?: Maybe<StringNullableFilter>;
  group?: Maybe<StringFilter>;
  template?: Maybe<StringFilter>;
  isRead?: Maybe<BoolFilter>;
  payload?: Maybe<JsonFilter>;
  entityId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  User?: Maybe<ProfileRelationFilter>;
  Company?: Maybe<CompanyNullableRelationFilter>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type OsDetails = {
  /** Value examples: 'Android', 'Windows', 'iOS', 'Mac OS'... */
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export enum OtpExceptionType {
  Email = 'email'
}

export type PaidAmountByReferralProgramToCompanyOutput = {
  amount: Scalars['String'];
  Asset: PublicAssetOutput;
};

export type PaymentMethod = {
  id: Scalars['String'];
  code: Scalars['String'];
  asset: Scalars['String'];
  transferType: TransferType;
  merchantId: Scalars['String'];
  network?: Maybe<Scalars['String']>;
  processor: Scalars['String'];
  payload: Scalars['JSON'];
  position: Scalars['Int'];
  saasOperation: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
};

export type PaymentMethodField = {
  name: Scalars['String'];
  label: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  type: Scalars['String'];
  isRequired?: Maybe<Scalars['Boolean']>;
  isMasked?: Maybe<Scalars['Boolean']>;
  isAlwaysFilledUser?: Maybe<Scalars['Boolean']>;
  isResultField?: Maybe<Scalars['Boolean']>;
  isReadOnly?: Maybe<Scalars['Boolean']>;
  validators: Array<Scalars['JSON']>;
};

export type PaymentMethodListRelationFilter = {
  every?: Maybe<PaymentMethodWhereInput>;
  some?: Maybe<PaymentMethodWhereInput>;
  none?: Maybe<PaymentMethodWhereInput>;
};

export type PaymentMethodMessage = {
  type: PaymentMethodPayloadMessageType;
  header: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum PaymentMethodNetwork {
  Test = 'Test',
  Corefy = 'Corefy',
  Kuna = 'Kuna',
  Trc20 = 'TRC20',
  Erc20 = 'ERC20'
}

export type PaymentMethodNullableRelationFilter = {
  is?: Maybe<PaymentMethodWhereInput>;
  isNot?: Maybe<PaymentMethodWhereInput>;
};

export enum PaymentMethodPayloadMessageType {
  Warning = 'warning',
  Info = 'info',
  Label = 'label'
}

export type PaymentMethodRelationFilter = {
  is?: Maybe<PaymentMethodWhereInput>;
  isNot?: Maybe<PaymentMethodWhereInput>;
};

export type PaymentMethodStubInput = {
  network: PaymentMethodNetwork;
};

export type PaymentMethodWhereInput = {
  AND?: Maybe<Array<PaymentMethodWhereInput>>;
  OR?: Maybe<Array<PaymentMethodWhereInput>>;
  NOT?: Maybe<Array<PaymentMethodWhereInput>>;
  id?: Maybe<StringFilter>;
  code?: Maybe<StringFilter>;
  asset?: Maybe<StringFilter>;
  transferType?: Maybe<EnumTransferTypeFilter>;
  merchantId?: Maybe<StringFilter>;
  network?: Maybe<StringNullableFilter>;
  processor?: Maybe<StringFilter>;
  payload?: Maybe<JsonFilter>;
  position?: Maybe<IntFilter>;
  saasOperation?: Maybe<StringFilter>;
  enabled?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  Asset?: Maybe<AssetRelationFilter>;
  Transfer?: Maybe<TransferListRelationFilter>;
  Invoice?: Maybe<InvoiceListRelationFilter>;
  PayoutOperation?: Maybe<PayoutOperationListRelationFilter>;
};

export type PayoutCallbackSettingsInput = {
  url: Scalars['String'];
  shouldSendTransactionalCallback?: Maybe<Scalars['Boolean']>;
};

export type PayoutInitiatorsOutput = {
  Create?: Maybe<InitiatorProfileDetailsOutput>;
  Launch?: Maybe<InitiatorProfileDetailsOutput>;
  /** If the payout was created through the API key,then the fields "Create" and "Launch" will be empty  */
  ApiKey?: Maybe<InitiatorApiKeyOutput>;
};

export enum PayoutIssueCode {
  DuplicatePayoutRequisite = 'DUPLICATE_PAYOUT_REQUISITE',
  PaymentMethodNotSupported = 'PAYMENT_METHOD_NOT_SUPPORTED',
  PaymentMethodAmountValidation = 'PAYMENT_METHOD_AMOUNT_VALIDATION',
  PaymentMethodValidation = 'PAYMENT_METHOD_VALIDATION',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  PaymentMethodDelisted = 'PAYMENT_METHOD_DELISTED'
}

export type PayoutIssueOutput = {
  code: PayoutIssueCode;
  contractorId?: Maybe<Scalars['String']>;
  asset?: Maybe<Scalars['String']>;
  requisiteFields?: Maybe<Scalars['JSON']>;
  message: Scalars['String'];
  isCritical: Scalars['String'];
  contractorIndex?: Maybe<Scalars['Float']>;
  paymentMethodField?: Maybe<Scalars['String']>;
};

export type PayoutListRelationFilter = {
  every?: Maybe<PayoutWhereInput>;
  some?: Maybe<PayoutWhereInput>;
  none?: Maybe<PayoutWhereInput>;
};

export type PayoutOperationListRelationFilter = {
  every?: Maybe<PayoutOperationWhereInput>;
  some?: Maybe<PayoutOperationWhereInput>;
  none?: Maybe<PayoutOperationWhereInput>;
};

export type PayoutOperationNullableRelationFilter = {
  is?: Maybe<PayoutOperationWhereInput>;
  isNot?: Maybe<PayoutOperationWhereInput>;
};

export type PayoutOperationPaymentDetailsOutput = {
  receiverName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
  toKunaPro?: Maybe<Scalars['String']>;
  toKunaPay?: Maybe<Scalars['String']>;
};

export type PayoutOperationPublicWhereInput = {
  id?: Maybe<StringFilter>;
  payoutId?: Maybe<StringFilter>;
  paymentMethodId?: Maybe<StringFilter>;
  status?: Maybe<EnumPayoutOperationStatusFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export enum PayoutOperationStatus {
  Created = 'Created',
  Processing = 'Processing',
  Processed = 'Processed',
  Failed = 'Failed',
  Declined = 'Declined'
}

export type PayoutOperationWhereInput = {
  AND?: Maybe<Array<PayoutOperationWhereInput>>;
  OR?: Maybe<Array<PayoutOperationWhereInput>>;
  NOT?: Maybe<Array<PayoutOperationWhereInput>>;
  id?: Maybe<StringFilter>;
  payoutId?: Maybe<StringFilter>;
  paymentMethodId?: Maybe<StringFilter>;
  originalAmount?: Maybe<DecimalFilter>;
  payload?: Maybe<JsonFilter>;
  publicReason?: Maybe<StringNullableListFilter>;
  reason?: Maybe<StringNullableListFilter>;
  status?: Maybe<EnumPayoutOperationStatusFilter>;
  supportStatus?: Maybe<EnumPayoutOperationStatusFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  Payout?: Maybe<PayoutRelationFilter>;
  Transfer?: Maybe<TransferNullableRelationFilter>;
  PaymentMethod?: Maybe<PaymentMethodRelationFilter>;
};

export type PayoutOrderByInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrderInput>;
  description?: Maybe<SortOrderInput>;
  creatorId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrderInput>;
  launchedAt?: Maybe<SortOrderInput>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
};

export type PayoutPaymentSummaryListRelationFilter = {
  every?: Maybe<PayoutPaymentSummaryWhereInput>;
  some?: Maybe<PayoutPaymentSummaryWhereInput>;
  none?: Maybe<PayoutPaymentSummaryWhereInput>;
};

export type PayoutPaymentSummaryWhereInput = {
  AND?: Maybe<Array<PayoutPaymentSummaryWhereInput>>;
  OR?: Maybe<Array<PayoutPaymentSummaryWhereInput>>;
  NOT?: Maybe<Array<PayoutPaymentSummaryWhereInput>>;
  id?: Maybe<StringFilter>;
  payoutId?: Maybe<StringFilter>;
  asset?: Maybe<StringFilter>;
  totalAmount?: Maybe<DecimalFilter>;
  totalTxCount?: Maybe<IntFilter>;
  paidAmount?: Maybe<DecimalFilter>;
  paidFee?: Maybe<DecimalFilter>;
  paidTxCount?: Maybe<IntFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  Payout?: Maybe<PayoutRelationFilter>;
  Asset?: Maybe<AssetRelationFilter>;
};

export type PayoutRelationFilter = {
  is?: Maybe<PayoutWhereInput>;
  isNot?: Maybe<PayoutWhereInput>;
};

export enum PayoutScalarFieldEnum {
  Id = 'id',
  CompanyId = 'companyId',
  ExternalId = 'externalId',
  Title = 'title',
  Description = 'description',
  IsCreatedByApi = 'isCreatedByApi',
  CreatorId = 'creatorId',
  LaunchInitiatorId = 'launchInitiatorId',
  Payload = 'payload',
  Status = 'status',
  SupportStatus = 'supportStatus',
  CompletedAt = 'completedAt',
  LaunchedAt = 'launchedAt',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LogicalTime = 'logicalTime'
}

export enum PayoutStatus {
  Created = 'Created',
  Processing = 'Processing',
  Processed = 'Processed',
  Failed = 'Failed'
}

export enum PayoutSupportStatus {
  Created = 'Created',
  Launched = 'Launched',
  Processing = 'Processing',
  Processed = 'Processed',
  Failed = 'Failed'
}

export type PayoutWhereInput = {
  AND?: Maybe<Array<PayoutWhereInput>>;
  OR?: Maybe<Array<PayoutWhereInput>>;
  NOT?: Maybe<Array<PayoutWhereInput>>;
  id?: Maybe<StringFilter>;
  companyId?: Maybe<StringFilter>;
  externalId?: Maybe<StringNullableFilter>;
  title?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  isCreatedByApi?: Maybe<BoolFilter>;
  creatorId?: Maybe<StringFilter>;
  launchInitiatorId?: Maybe<StringNullableFilter>;
  payload?: Maybe<JsonFilter>;
  status?: Maybe<EnumPayoutStatusFilter>;
  supportStatus?: Maybe<EnumPayoutSupportStatusFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  launchedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  Company?: Maybe<CompanyRelationFilter>;
  PayoutOperation?: Maybe<PayoutOperationListRelationFilter>;
  PayoutPaymentSummary?: Maybe<PayoutPaymentSummaryListRelationFilter>;
};

export type PreRequestInvoiceOutput = {
  id: Scalars['String'];
  /** Invoice amount without fee */
  amount: Scalars['Decimal'];
  /** Invoice amount with fee */
  processedAmount: Scalars['Decimal'];
  /** Fee amount */
  fee: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateFreezeAt: Scalars['DateTime'];
  isCanBePaid: Scalars['Boolean'];
  issues?: Maybe<Array<Scalars['String']>>;
};

export type PreRequestPayoutAmountOutput = {
  asset: Scalars['String'];
  /** Total amount with fee */
  amountWithFee: Scalars['String'];
  /** Total fee */
  fee: Scalars['String'];
};

export type PreRequestPayoutOutput = {
  success: Scalars['Boolean'];
  issues?: Maybe<Array<PayoutIssueOutput>>;
  totalAmounts: Array<PreRequestPayoutAmountOutput>;
};

export type PresignFeedbackAttachmentUrlInput = {
  filename: Scalars['String'];
  contentType: Scalars['String'];
};

export type PresignFeedbackAttachmentUrlsOutput = {
  urls: Array<Scalars['String']>;
};

export type ProductDiscountOutput = {
  discountFeeRate?: Maybe<Scalars['String']>;
  originalFeeRate?: Maybe<Scalars['String']>;
};

export enum ProductFeeStrategy {
  Internal = 'internal',
  External = 'external'
}

export type ProductMeta = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  by: Scalars['String'];
  byValues: Scalars['String'];
};

export type ProductOutput = {
  minAmount?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['String']>;
  feeFixed?: Maybe<Scalars['String']>;
  feeRate?: Maybe<Scalars['String']>;
  feeMin?: Maybe<Scalars['String']>;
  feeStrategy?: Maybe<ProductFeeStrategy>;
  disabled?: Maybe<Scalars['Boolean']>;
  isNonNativeFee?: Maybe<Scalars['Boolean']>;
  feeNonNativeCurrency?: Maybe<Scalars['String']>;
  feeMinNonNative?: Maybe<Scalars['String']>;
  feeFixedNonNative?: Maybe<Scalars['String']>;
  referralPayoutPercent?: Maybe<Scalars['String']>;
  meta: ProductMeta;
};

export type ProfileListRelationFilter = {
  every?: Maybe<ProfileWhereInput>;
  some?: Maybe<ProfileWhereInput>;
  none?: Maybe<ProfileWhereInput>;
};

export enum ProfileNotificationEntities {
  Login = 'login',
  Advertisement = 'advertisement'
}

export type ProfileNotificationSettingsOutput = {
  login: NotificationSettingsDetailsOutput;
  advertisement: NotificationSettingsDetailsOutput;
};

export type ProfileNullableRelationFilter = {
  is?: Maybe<ProfileWhereInput>;
  isNot?: Maybe<ProfileWhereInput>;
};

export type ProfileRelationFilter = {
  is?: Maybe<ProfileWhereInput>;
  isNot?: Maybe<ProfileWhereInput>;
};

export type ProfileWhereInput = {
  AND?: Maybe<Array<ProfileWhereInput>>;
  OR?: Maybe<Array<ProfileWhereInput>>;
  NOT?: Maybe<Array<ProfileWhereInput>>;
  id?: Maybe<StringFilter>;
  sn?: Maybe<IntFilter>;
  username?: Maybe<StringFilter>;
  avatar?: Maybe<StringNullableFilter>;
  language?: Maybe<StringFilter>;
  fullName?: Maybe<StringFilter>;
  firstName?: Maybe<StringNullableFilter>;
  lastName?: Maybe<StringNullableFilter>;
  identificationStatus?: Maybe<EnumIdentificationStatusFilter>;
  merchantId?: Maybe<StringFilter>;
  role?: Maybe<StringFilter>;
  isSuspicious?: Maybe<BoolFilter>;
  isKUNAEmployee?: Maybe<BoolFilter>;
  isAgreePrivacyPolicy?: Maybe<BoolFilter>;
  status?: Maybe<EnumStatusOfUserFilter>;
  utm?: Maybe<StringFilter>;
  affiliateId?: Maybe<StringNullableFilter>;
  referenceId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  NotificationSetting?: Maybe<NotificationSettingsNullableRelationFilter>;
  ConfirmationSetting?: Maybe<ConfirmationSettingNullableRelationFilter>;
  Requisite?: Maybe<RequisiteListRelationFilter>;
  CompanyRelation?: Maybe<RelationUserToCompanyListRelationFilter>;
  Note?: Maybe<NoteListRelationFilter>;
  CreatedNote?: Maybe<NoteListRelationFilter>;
  Affiliate?: Maybe<CompanyNullableRelationFilter>;
  Notification?: Maybe<NotificationListRelationFilter>;
  NotificationSettings?: Maybe<CustomNotificationSettingsListRelationFilter>;
  CompleteInvoice?: Maybe<InvoiceListRelationFilter>;
};

export type PublicAssetOutput = {
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  position: Scalars['Int'];
  precision: Scalars['Int'];
  payload: Scalars['JSON'];
  type: AssetType;
  rateFreezeSeconds: Scalars['Int'];
  minInvoiceAmount: Scalars['Decimal'];
  isInvoiceCurrency: Scalars['Boolean'];
  isPaymentCurrency: Scalars['Boolean'];
  isReferenceCurrency: Scalars['Boolean'];
  isAutoConvertCurrency: Scalars['Boolean'];
  isReferralPayoutCurrency: Scalars['Boolean'];
};

export type PublicAssetWithSettingsOutput = {
  code: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  position: Scalars['Int'];
  precision: Scalars['Int'];
  payload: Scalars['JSON'];
  type: AssetType;
  rateFreezeSeconds: Scalars['Int'];
  minInvoiceAmount: Scalars['Decimal'];
  isInvoiceCurrency: Scalars['Boolean'];
  isPaymentCurrency: Scalars['Boolean'];
  isReferenceCurrency: Scalars['Boolean'];
  isAutoConvertCurrency: Scalars['Boolean'];
  isReferralPayoutCurrency: Scalars['Boolean'];
  AssetSettings?: Maybe<AssetSettingsOutput>;
};

export type PublicPaymentMethodOutput = {
  id: Scalars['String'];
  code: Scalars['String'];
  asset: Scalars['String'];
  transferType: TransferType;
  merchantId: Scalars['String'];
  network?: Maybe<Scalars['String']>;
  processor: Scalars['String'];
  payload: Scalars['JSON'];
  position: Scalars['Int'];
  saasOperation: Scalars['String'];
  enabled: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  customTitle?: Maybe<Scalars['String']>;
  purposeField?: Maybe<Scalars['String']>;
  isPrefill?: Maybe<Scalars['Boolean']>;
  maxRequisiteCount?: Maybe<Scalars['Int']>;
  isRequisiteUpdateDisabled?: Maybe<Scalars['Boolean']>;
  isRequisiteDeleteDisabled?: Maybe<Scalars['Boolean']>;
  trustWalletCoinId?: Maybe<Scalars['String']>;
  fields: Array<PaymentMethodField>;
  messages: Array<PaymentMethodMessage>;
  Product: PublicProductOutput;
  Asset?: Maybe<PublicAssetOutput>;
};

export type PublicPaymentMethodWithAssetOutput = {
  id: Scalars['String'];
  code: Scalars['String'];
  asset: Scalars['String'];
  network?: Maybe<Scalars['String']>;
  processor: Scalars['String'];
  payload: Scalars['JSON'];
  position: Scalars['Int'];
  Asset: PublicAssetOutput;
};

export type PublicPayoutOperationOutput = {
  id: Scalars['String'];
  originalAmount: Scalars['Decimal'];
  publicReason: Array<Scalars['String']>;
  status: PayoutOperationStatus;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type PublicPayoutPaymentSummaryOutput = {
  id: Scalars['String'];
  payoutId: Scalars['String'];
  asset: Scalars['String'];
  totalAmount: Scalars['Decimal'];
  totalTxCount: Scalars['Int'];
  paidAmount: Scalars['Decimal'];
  paidFee: Scalars['Decimal'];
  paidTxCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  Asset: PublicAssetOutput;
};

export type PublicProductOutput = {
  minAmount?: Maybe<Scalars['String']>;
  maxAmount?: Maybe<Scalars['String']>;
  feeFixed?: Maybe<Scalars['String']>;
  feeRate?: Maybe<Scalars['String']>;
  feeMin?: Maybe<Scalars['String']>;
  feeStrategy?: Maybe<ProductFeeStrategy>;
  referralPayoutPercent?: Maybe<Scalars['String']>;
  meta: ProductMeta;
};

export type PublicTransferOutput = {
  id: Scalars['String'];
  status: TransferStatus;
  type: TransferType;
  invoiceId?: Maybe<Scalars['String']>;
  asset: Scalars['String'];
  amount: Scalars['Decimal'];
  processedAmount?: Maybe<Scalars['Decimal']>;
  fee?: Maybe<Scalars['Decimal']>;
  companyId: Scalars['String'];
  paymentCode: Scalars['String'];
  reason: Array<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  convertId?: Maybe<Scalars['String']>;
  payoutOperationId?: Maybe<Scalars['String']>;
  isCreatedByApi: Scalars['Boolean'];
  creatorId?: Maybe<Scalars['String']>;
  creatorComment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  merchantId: Scalars['String'];
  logicalTime: Scalars['BigInt'];
  Asset: PublicAssetOutput;
  PaymentMethod: PaymentMethod;
};

export type PublicTransferWithAmlDetailsOutput = {
  id: Scalars['String'];
  status: TransferStatus;
  type: TransferType;
  invoiceId?: Maybe<Scalars['String']>;
  asset: Scalars['String'];
  amount: Scalars['Decimal'];
  processedAmount?: Maybe<Scalars['Decimal']>;
  fee?: Maybe<Scalars['Decimal']>;
  companyId: Scalars['String'];
  paymentCode: Scalars['String'];
  reason: Array<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  convertId?: Maybe<Scalars['String']>;
  payoutOperationId?: Maybe<Scalars['String']>;
  isCreatedByApi: Scalars['Boolean'];
  creatorId?: Maybe<Scalars['String']>;
  creatorComment?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  merchantId: Scalars['String'];
  logicalTime: Scalars['BigInt'];
  Asset: PublicAssetOutput;
  PaymentMethod: PaymentMethod;
  Details?: Maybe<TransferDetailsOutput>;
  AmlDetails?: Maybe<TransferAmlDetailsOutput>;
  Convert?: Maybe<ConvertPublicOutput>;
};

export type PublicUserLog = {
  id: Scalars['String'];
  group: Scalars['String'];
  action: Scalars['String'];
  details: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
};

export type Query = {
  features: Array<Feature>;
  publicFeatures: Array<Feature>;
  clearApiKeyCache: SuccessOutput;
  getErrors: Array<Exception>;
  getServiceInfo: ServiceInfo;
  checkOne: CheckResult;
  checkMany: Array<CheckResult>;
  checkAll: Array<CheckResult>;
  remoteConfigs: Array<RemoteConfig>;
  getRabacRoles: AvailableRabacRolesOutput;
  rabacSchema: RabacSchema;
  /** Endpoint for refreshing access token */
  refreshToken: AuthTokensOutput;
  /** Generate API key pair */
  generateKeys: GenerateKeysOutput;
  /** Get list active api keys */
  findManyApiKeys: Array<ApiKeyOutput>;
  login: AuthTokensOutput;
  /** Login throughout google */
  googleLogin: AuthTokensOutput;
  /** Login to company as coworker */
  loginToCompany: AuthTokensOutput;
  /** Login throughout kuna */
  kunaLogin: AuthTokensOutput;
  /** Check exists by kuna token */
  kunaCheckExistsByToken: Scalars['Boolean'];
  /** Generate otp(2fa) Uri */
  generateOtpUri: GenerateOtpUriOutput;
  resendLoginOtpToEmail: SuccessOutput;
  resendOtpToEmail: SuccessOutput;
  findManyActiveSession: Array<SessionDetailsOutput>;
  findManyExpiredSession: Array<SessionDetailsOutput>;
  zenGetAccountLinkStatus: GetAccountLinkStatus;
  findCurrentIdentStatus: SuccessOutput;
  me: UserProfileInformationOutput;
  checkUsernameExists: CheckUsernameOutput;
  checkUsernameExistsOnKunaPro: CheckUsernameOutput;
  /** Get status of user notification settings */
  findUniqueUserNotificationSettings: NotificationSettingsOutput;
  /** Get status of user notification settings */
  findUniqueNotificationByCompanySettings: NotificationSettingsByCompanyOutput;
  findUserNotificationSettings: ProfileNotificationSettingsOutput;
  findCompanyNotificationSettings: CompanyNotificationSettingsOutput;
  /** Find all companies where user is coworker */
  findRelatedCompanies: Array<FindRelatedCompaniesOutput>;
  /** Find current company details(CompanyId takes from Jwt token) */
  findCompanyDetails: CompanyDetailsOutput;
  /** Find available types to create company */
  findAvailableTypesForCreateCompany: AvailableTypesForCreateCompanyOutput;
  /** Request to change the commission strategy for company */
  requestToChangeCommissionStrategyForCompany: SuccessOutput;
  checkCompanyExists: CheckCompanyExistOutput;
  findManyCompanyRelations: Array<CompanyRelationOutput>;
  findAvailableCoworkerRoles: AvailableCoworkerRolesOutput;
  /** Find many available Assets in System */
  findManyAsset: Array<PublicAssetWithSettingsOutput>;
  /** Get the current balance of the company */
  findManyCompanyAssets: Array<CompanyAsset>;
  findManyRate: Array<Rate>;
  findManyRateForNonAuth: Array<Rate>;
  calculatePaidAmountByReferralProgramToCompany: Array<PaidAmountByReferralProgramToCompanyOutput>;
  findCountOfActiveReferralByCompany: CountOfActiveReferralByCompany;
  findManyNotifications: Array<NotificationOutput>;
  findCountNotReadNotification: FindCountNotReadNotificationOutput;
  findCountNotReadNotificationByGroup: Array<FindCountNotReadNotificationByGroupOutput>;
  markManyNotificationAsRead: SuccessOutput;
  markAllNotificationAsRead: SuccessOutput;
  findManyCoworkerInvites: Array<FindInvitedCoworkerOutput>;
  stubForSharingTypingInGraphQL: SuccessOutput;
  /** Get status of user confirmation settings */
  findUniqueConfirmationSetting: ConfirmationSetting;
  findManyLanguages: Array<Language>;
  findActualMaintenance?: Maybe<MaintenanceNotificationOutput>;
  findManyPreventMaintenance?: Maybe<Array<MaintenanceNotificationOutput>>;
  findManyAvailablePaymentMethods: Array<PublicPaymentMethodOutput>;
  getAssetPaymentMethodPrefill: Scalars['JSON'];
  findGroupedByAssetPaymentMethod?: Maybe<Array<GroupedByAssetPaymentMethodWithProductPublicOutput>>;
  findReferralInvoicePaymentDiscountForCompany?: Maybe<ProductDiscountOutput>;
  findGroupedByAssetAutoConvertPaymentMethod?: Maybe<Array<GroupedByAssetAutoConvertPaymentMethodPublicOutput>>;
  validateCryptoAddressBlockListByPaymentMethod: ValidateCryptoAddressBlockListByPaymentMethodOutput;
  findManyCompanyTransfers: Array<CompanyTransferOutput>;
  findManyMyOwnCompanyTransfers: Array<CompanyTransferOutput>;
  findUniqueCompanyTransfer: CompanyTransferOutput;
  findUniqueMyOwnCompanyTransfer: CompanyTransferOutput;
  /** Use for get constant deposit address, can be empty if address not created for source */
  findConstDepositAddress?: Maybe<DepositAddressPublicOutput>;
  findManyInvoiceRelatedToCompany: Array<FindManyInvoiceOutput>;
  findManyMyOwnInvoiceInCompany: Array<FindManyInvoiceOutput>;
  findInvoiceDetails: InvoiceDetailsOutput;
  findCompanyStatisticByInvoices: CompanyStatisticByInvoicesOutput;
  findPublicPaymentMethodsToPayInvoice: Array<PublicPaymentMethodOutput>;
  findPublicInvoiceById: NonAuthInvoiceDetailsOutput;
  findAssetForConvertInvoice: Array<PublicAssetOutput>;
  findOneQuestionnaireToFill?: Maybe<QuestionnaireForPublicOutput>;
  findOneQuestionnaireByEntity?: Maybe<QuestionnaireForPublicOutput>;
  findAnswersToQuestionnaireByEntity?: Maybe<QuestionnaireForPublicOutput>;
  findAvailableFieldsForQuestionnaire: Array<QuestionnaireFieldOutput>;
  findOneQuestionnaireToFillForNonAuth?: Maybe<QuestionnaireForNonAuthOutput>;
  findManyCompanyLimit: Array<CompanyLimitOutput>;
  /** Get user saved withdraw/deposit requisites */
  findManyRequisite: Array<Requisite>;
  /** Get requisite values */
  getRequisiteValues: RequisiteValuesOutput;
  findManyReport: Array<ReportOutput>;
  findDetailsForDonationPageNonAuth: FindDetailsForDonationPageNonAuthOutput;
  findDonationDetailsForNonAuth?: Maybe<FindDonationForNonAuthOutput>;
  /** Find many payout */
  findManyCompanyPayout: Array<FindManyCompanyPayoutOutput>;
  /** Find payout details */
  findCompanyPayoutDetails: FindCompanyPayoutDetailsOutput;
  /** Find many payout operations and Transfer */
  findManyPayoutOperationByPayout: Array<FindManyPayoutOperationOutput>;
  findCocktailPromoDetails?: Maybe<CocktailPromoOutput>;
  findCocktailPromoCustomPriceDetails?: Maybe<CocktailPromoOutput>;
  findManyCurrentUserActionHistory: Array<PublicUserLog>;
  findManyCurrentCompanyActionHistory: Array<PublicUserLog>;
};


export type QueryClearApiKeyCacheArgs = {
  apiKey: Scalars['String'];
};


export type QueryCheckOneArgs = {
  module: Scalars['String'];
};


export type QueryCheckManyArgs = {
  modules: Array<Scalars['String']>;
};


export type QueryRemoteConfigsArgs = {
  group?: Maybe<Scalars['String']>;
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type QueryFindManyApiKeysArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ApiKeyOrderByInput>>;
};


export type QueryLoginArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
  isTrustedDevice?: Maybe<Scalars['Boolean']>;
};


export type QueryGoogleLoginArgs = {
  token?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  isTrustedDevice?: Maybe<Scalars['Boolean']>;
  utm?: Maybe<Scalars['String']>;
  isSubscribe?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['String']>;
  referrerId?: Maybe<Scalars['String']>;
  isAgreePrivacyPolicy?: Maybe<Scalars['Boolean']>;
};


export type QueryLoginToCompanyArgs = {
  companyId: Scalars['String'];
};


export type QueryKunaLoginArgs = {
  token: Scalars['String'];
  isTrustedDevice?: Maybe<Scalars['Boolean']>;
};


export type QueryKunaCheckExistsByTokenArgs = {
  token: Scalars['String'];
};


export type QueryResendLoginOtpToEmailArgs = {
  email: Scalars['String'];
};


export type QueryFindManyActiveSessionArgs = {
  orderBy?: Maybe<Array<SessionOrderByInput>>;
};


export type QueryFindManyExpiredSessionArgs = {
  orderBy?: Maybe<Array<SessionOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCheckUsernameExistsArgs = {
  username: Scalars['String'];
};


export type QueryCheckUsernameExistsOnKunaProArgs = {
  username: Scalars['String'];
};


export type QueryFindRelatedCompaniesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryRequestToChangeCommissionStrategyForCompanyArgs = {
  preferredStrategy?: Maybe<ProductFeeStrategy>;
};


export type QueryCheckCompanyExistsArgs = {
  companyId: Scalars['String'];
};


export type QueryFindManyCompanyRelationsArgs = {
  where?: Maybe<FindCompanyRelationWhereInput>;
  orderBy?: Maybe<Array<CompanyRelationDetailsOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindManyAssetArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FindManyPublicAssetWhereInput>;
  orderBy?: Maybe<Array<AssetOrderByInput>>;
};


export type QueryFindManyCompanyAssetsArgs = {
  assetCodes?: Maybe<Array<Scalars['String']>>;
};


export type QueryFindManyNotificationsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FindNotificationsWhereInput>;
};


export type QueryMarkManyNotificationAsReadArgs = {
  ids: Array<Scalars['String']>;
};


export type QueryFindManyCoworkerInvitesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryStubForSharingTypingInGraphQlArgs = {
  PaymentMethod?: Maybe<PaymentMethodStubInput>;
};


export type QueryFindManyAvailablePaymentMethodsArgs = {
  where: FindManyAvailablePaymentMethodWhereInput;
  orderBy?: Maybe<Array<FindManyAvailablePaymentMethodOrderByInput>>;
};


export type QueryGetAssetPaymentMethodPrefillArgs = {
  asset: Scalars['String'];
  code: Scalars['String'];
  type: TransferType;
};


export type QueryFindGroupedByAssetPaymentMethodArgs = {
  counterpartyGroupType: TypeOfCounterpartyGroup;
  where: PaymentMethodWhereInput;
};


export type QueryValidateCryptoAddressBlockListByPaymentMethodArgs = {
  blockchain: Scalars['String'];
  cryptoAddress: Scalars['String'];
};


export type QueryFindManyCompanyTransfersArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FindManyCompanyTransferWhereInput>;
  orderBy?: Maybe<CompanyTransferOrderInput>;
};


export type QueryFindManyMyOwnCompanyTransfersArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FindManyCompanyTransferWhereInput>;
  orderBy?: Maybe<CompanyTransferOrderInput>;
};


export type QueryFindUniqueCompanyTransferArgs = {
  where: RecordIdInput;
};


export type QueryFindUniqueMyOwnCompanyTransferArgs = {
  where: RecordIdInput;
};


export type QueryFindConstDepositAddressArgs = {
  source: Scalars['String'];
};


export type QueryFindManyInvoiceRelatedToCompanyArgs = {
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FindManyInvoiceWhereInput>;
  orderBy?: Maybe<Array<FindManyInvoiceOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryFindManyMyOwnInvoiceInCompanyArgs = {
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FindManyInvoiceWhereInput>;
  orderBy?: Maybe<Array<FindManyInvoiceOrderByInput>>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryFindInvoiceDetailsArgs = {
  id: Scalars['String'];
};


export type QueryFindPublicPaymentMethodsToPayInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryFindPublicInvoiceByIdArgs = {
  id: Scalars['String'];
};


export type QueryFindAssetForConvertInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryFindOneQuestionnaireToFillArgs = {
  entityId?: Maybe<Scalars['String']>;
};


export type QueryFindOneQuestionnaireByEntityArgs = {
  entityId?: Maybe<Scalars['String']>;
};


export type QueryFindAnswersToQuestionnaireByEntityArgs = {
  entityId?: Maybe<Scalars['String']>;
};


export type QueryFindOneQuestionnaireToFillForNonAuthArgs = {
  entityId?: Maybe<Scalars['String']>;
};


export type QueryFindManyCompanyLimitArgs = {
  where: FindManyCompanyLimitWhere;
};


export type QueryFindManyRequisiteArgs = {
  source: Scalars['String'];
  currency: Scalars['String'];
  type: RequisiteType;
};


export type QueryGetRequisiteValuesArgs = {
  id: Scalars['String'];
};


export type QueryFindManyReportArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<FindManyReportWhereInput>;
  orderBy?: Maybe<Array<FindManyReportOrderByInput>>;
};


export type QueryFindDetailsForDonationPageNonAuthArgs = {
  companyId: Scalars['String'];
};


export type QueryFindDonationDetailsForNonAuthArgs = {
  companyId: Scalars['String'];
  addressId: Scalars['String'];
};


export type QueryFindManyCompanyPayoutArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PayoutScalarFieldEnum>>;
  where?: Maybe<FindManyCompanyPayoutWhereInput>;
  orderBy?: Maybe<Array<PayoutOrderByInput>>;
};


export type QueryFindCompanyPayoutDetailsArgs = {
  id: Scalars['String'];
};


export type QueryFindManyPayoutOperationByPayoutArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  where: FindManyPayoutOperationByPayoutWhereInput;
  orderBy?: Maybe<Array<FindManyPayoutOperationByPayoutOrderByInput>>;
};


export type QueryFindCocktailPromoDetailsArgs = {
  companyId: Scalars['String'];
};


export type QueryFindCocktailPromoCustomPriceDetailsArgs = {
  companyId: Scalars['String'];
  usdtAmount: Scalars['String'];
};


export type QueryFindManyCurrentUserActionHistoryArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFindManyCurrentCompanyActionHistoryArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type QuestionnaireDetailsForNonAuthOutput = {
  schema?: Maybe<Array<QuestionnaireFieldOutput>>;
  isFilled: Scalars['Boolean'];
};

export type QuestionnaireDetailsOutput = {
  answers?: Maybe<Scalars['JSON']>;
  schema?: Maybe<Array<QuestionnaireFieldOutput>>;
  isFilled: Scalars['Boolean'];
};

export enum QuestionnaireFieldName {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
  BillingAddress = 'billing_address',
  Custom = 'custom'
}

export type QuestionnaireFieldOutput = {
  name: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  position: Scalars['Float'];
  type: QuestionnaireFieldType;
  validators: Array<QuestionnaireValidatorOutput>;
  isCustom?: Maybe<Scalars['Boolean']>;
  /** Needed only for types sharing */
  defaultQuestionnaireFieldNames?: Maybe<QuestionnaireFieldName>;
};

export enum QuestionnaireFieldType {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  BillingAddress = 'BILLING_ADDRESS',
  Email = 'EMAIL',
  Text = 'TEXT'
}

export enum QuestionnaireFieldValidatorType {
  Max = 'MAX',
  Min = 'MIN',
  EmaIl = 'EMAIl'
}

export type QuestionnaireForNonAuthOutput = {
  id: Scalars['String'];
  Details: QuestionnaireDetailsForNonAuthOutput;
};

export type QuestionnaireForPublicOutput = {
  id: Scalars['String'];
  Details: QuestionnaireDetailsOutput;
};

export type QuestionnaireListRelationFilter = {
  every?: Maybe<QuestionnaireWhereInput>;
  some?: Maybe<QuestionnaireWhereInput>;
  none?: Maybe<QuestionnaireWhereInput>;
};

export type QuestionnaireValidatorOutput = {
  type: QuestionnaireFieldValidatorType;
  value?: Maybe<Scalars['Float']>;
};

export type QuestionnaireWhereInput = {
  AND?: Maybe<Array<QuestionnaireWhereInput>>;
  OR?: Maybe<Array<QuestionnaireWhereInput>>;
  NOT?: Maybe<Array<QuestionnaireWhereInput>>;
  id?: Maybe<StringFilter>;
  title?: Maybe<StringNullableFilter>;
  companyId?: Maybe<StringFilter>;
  entityId?: Maybe<StringNullableFilter>;
  payload?: Maybe<JsonFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  Company?: Maybe<CompanyRelationFilter>;
};

export type RabacSchema = {
  schema: Scalars['String'];
};

export type Rate = {
  currency: Scalars['String'];
  equivalent: Array<Equivalent>;
};

export type RecordIdInput = {
  id: Scalars['String'];
};

export type RelationUserToCompanyListRelationFilter = {
  every?: Maybe<RelationUserToCompanyWhereInput>;
  some?: Maybe<RelationUserToCompanyWhereInput>;
  none?: Maybe<RelationUserToCompanyWhereInput>;
};

export type RelationUserToCompanyRelationFilter = {
  is?: Maybe<RelationUserToCompanyWhereInput>;
  isNot?: Maybe<RelationUserToCompanyWhereInput>;
};

export type RelationUserToCompanyWhereInput = {
  AND?: Maybe<Array<RelationUserToCompanyWhereInput>>;
  OR?: Maybe<Array<RelationUserToCompanyWhereInput>>;
  NOT?: Maybe<Array<RelationUserToCompanyWhereInput>>;
  companyId?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  role?: Maybe<StringFilter>;
  isActive?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  Profile?: Maybe<ProfileRelationFilter>;
  Company?: Maybe<CompanyRelationFilter>;
  NotificationByCompanySettings?: Maybe<NotificationByCompanySettingsNullableRelationFilter>;
};

export type RemoteConfig = {
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  mod: Scalars['Float'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  group: Scalars['String'];
};

export type ReportDetailsOutput = {
  dateRange: DateRangeOutput;
  format: ReportFileFormat;
  url?: Maybe<Scalars['String']>;
};

export enum ReportFileFormat {
  Csv = 'CSV',
  Pdf = 'PDF'
}

export type ReportOutput = {
  id: Scalars['String'];
  companyId?: Maybe<Scalars['String']>;
  type: ReportType;
  status: ReportStatus;
  expireAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  Details: ReportDetailsOutput;
};

export enum ReportStatus {
  InProgress = 'InProgress',
  Completed = 'Completed',
  Expired = 'Expired'
}

export enum ReportType {
  Statement = 'Statement',
  Settlement = 'Settlement',
  Internal = 'Internal',
  Invoice = 'Invoice'
}

export type Requisite = {
  id: Scalars['String'];
  type: RequisiteType;
  source: Scalars['String'];
  currency: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  mask: Scalars['String'];
  userId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type RequisiteListRelationFilter = {
  every?: Maybe<RequisiteWhereInput>;
  some?: Maybe<RequisiteWhereInput>;
  none?: Maybe<RequisiteWhereInput>;
};

export enum RequisiteType {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw'
}

export type RequisiteValuesOutput = {
  name: Scalars['String'];
  values: Scalars['JSON'];
};

export type RequisiteWhereInput = {
  AND?: Maybe<Array<RequisiteWhereInput>>;
  OR?: Maybe<Array<RequisiteWhereInput>>;
  NOT?: Maybe<Array<RequisiteWhereInput>>;
  id?: Maybe<StringFilter>;
  type?: Maybe<EnumRequisiteTypeFilter>;
  source?: Maybe<StringFilter>;
  currency?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  mask?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  companyId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  Profile?: Maybe<ProfileRelationFilter>;
  Company?: Maybe<CompanyRelationFilter>;
  Currency?: Maybe<AssetRelationFilter>;
};

export type ServiceInfo = {
  name: Scalars['String'];
  version: Scalars['String'];
  uptime: Scalars['Float'];
  schemaVersion: Scalars['String'];
  schemaDependency: Scalars['JSON'];
  monitorConfig: Array<Scalars['String']>;
};

export enum ServiceName {
  KunaPayAuth = 'KUNA_PAY_AUTH',
  KunaPayCore = 'KUNA_PAY_CORE',
  KunaPayUserLog = 'KUNA_PAY_USER_LOG',
  KunaPayGateway = 'KUNA_PAY_GATEWAY'
}

export type SessionDetailsOutput = {
  key: Scalars['String'];
  createdAt: Scalars['DateTime'];
  endAt?: Maybe<Scalars['DateTime']>;
  device: Scalars['String'];
  userAgent: Scalars['String'];
  ip: Scalars['String'];
  location: Scalars['String'];
  fingerprint: Scalars['String'];
  jwtId: Scalars['String'];
  userId: Scalars['String'];
  logicalTime: Scalars['BigInt'];
  deviceInfo: DeviceInfo;
};

export type SessionOrderByInput = {
  createdAt?: Maybe<SortOrder>;
  endAt?: Maybe<SortOrder>;
};

export type SetRabacSchemaMutationInput = {
  schema: Scalars['String'];
};

export type SetRemoteConfigInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  sort: SortOrder;
  nulls?: Maybe<NullsOrder>;
};

export type StartSumSubIdentificationOutput = {
  token: Scalars['String'];
  isFullyFilled: Scalars['Boolean'];
};

export enum StatusOfCompany {
  Approved = 'APPROVED',
  Unapproved = 'UNAPPROVED',
  PermanentBlocked = 'PERMANENT_BLOCKED',
  Deleted = 'DELETED'
}

export enum StatusOfUser {
  Confirmed = 'CONFIRMED',
  Unconfirmed = 'UNCONFIRMED',
  Blocked = 'BLOCKED',
  PermanentBlocked = 'PERMANENT_BLOCKED',
  Deleted = 'DELETED',
  Invited = 'INVITED'
}

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type SuccessOutput = {
  success: Scalars['Boolean'];
};

export type TransferApiKeyCreatorOutput = {
  id: Scalars['String'];
  /** API key name */
  name?: Maybe<Scalars['String']>;
};

export type TransferAdditionalFieldsOutput = {
  /** Name of field, example: txId, address etc. */
  name: Scalars['String'];
  /** Title of field for show on Frontend */
  label: Scalars['String'];
  /** Value of field */
  value: Scalars['String'];
  /** Is it possible to copy the value on the front */
  isCopyable?: Maybe<Scalars['Boolean']>;
  /** Link to external resource for example transaction in blockchain */
  externalLink?: Maybe<Scalars['String']>;
};

export type TransferAmlAlertsOutput = {
  service: Scalars['String'];
  category: Scalars['String'];
  alertLevel: Scalars['String'];
  alertAmount: Scalars['Float'];
  exposureType: Scalars['String'];
  riskScore: Scalars['String'];
};

export type TransferAmlDetailsForCompanyOutput = {
  riskScore: Scalars['String'];
};

export type TransferAmlDetailsOutput = {
  alerts: Array<TransferAmlAlertsOutput>;
  riskScore: Scalars['String'];
};

export type TransferDetailsOutput = {
  txId?: Maybe<Scalars['String']>;
  linkToBlockchain?: Maybe<Scalars['String']>;
  autoConvertCurrency?: Maybe<Scalars['String']>;
  payoutContractorName?: Maybe<Scalars['String']>;
};

export type TransferLimitListRelationFilter = {
  every?: Maybe<TransferLimitWhereInput>;
  some?: Maybe<TransferLimitWhereInput>;
  none?: Maybe<TransferLimitWhereInput>;
};

export type TransferLimitWhereInput = {
  AND?: Maybe<Array<TransferLimitWhereInput>>;
  OR?: Maybe<Array<TransferLimitWhereInput>>;
  NOT?: Maybe<Array<TransferLimitWhereInput>>;
  id?: Maybe<StringFilter>;
  transferType?: Maybe<EnumTransferTypeFilter>;
  asset?: Maybe<StringFilter>;
  companyId?: Maybe<StringNullableFilter>;
  maxAmount?: Maybe<DecimalFilter>;
  countByDay?: Maybe<IntFilter>;
  countByMonth?: Maybe<IntFilter>;
  sumByDay?: Maybe<DecimalFilter>;
  sumByMonth?: Maybe<DecimalFilter>;
  riskScore?: Maybe<FloatFilter>;
  Asset?: Maybe<AssetRelationFilter>;
  Company?: Maybe<CompanyNullableRelationFilter>;
};

export type TransferListRelationFilter = {
  every?: Maybe<TransferWhereInput>;
  some?: Maybe<TransferWhereInput>;
  none?: Maybe<TransferWhereInput>;
};

export type TransferNullableRelationFilter = {
  is?: Maybe<TransferWhereInput>;
  isNot?: Maybe<TransferWhereInput>;
};

export type TransferRelationFilter = {
  is?: Maybe<TransferWhereInput>;
  isNot?: Maybe<TransferWhereInput>;
};

export enum TransferStatus {
  Created = 'Created',
  Canceled = 'Canceled',
  PartiallyProcessed = 'PartiallyProcessed',
  Processing = 'Processing',
  Processed = 'Processed',
  Suspended = 'Suspended',
  Arrested = 'Arrested'
}

export type TransferStatusHistory = {
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  supportStatus: TransferStatusSupport;
  transferId: Scalars['String'];
  supportEmail?: Maybe<Scalars['String']>;
  supportUserId?: Maybe<Scalars['String']>;
  reason: Array<Scalars['String']>;
};

export type TransferStatusHistoryListRelationFilter = {
  every?: Maybe<TransferStatusHistoryWhereInput>;
  some?: Maybe<TransferStatusHistoryWhereInput>;
  none?: Maybe<TransferStatusHistoryWhereInput>;
};

export type TransferStatusHistoryWhereInput = {
  AND?: Maybe<Array<TransferStatusHistoryWhereInput>>;
  OR?: Maybe<Array<TransferStatusHistoryWhereInput>>;
  NOT?: Maybe<Array<TransferStatusHistoryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  supportStatus?: Maybe<EnumTransferStatusSupportFilter>;
  transferId?: Maybe<StringFilter>;
  supportEmail?: Maybe<StringNullableFilter>;
  supportUserId?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableListFilter>;
  Transfer?: Maybe<TransferRelationFilter>;
};

export enum TransferStatusSupport {
  Canceled = 'Canceled',
  Declined = 'Declined',
  Rejected = 'Rejected',
  Created = 'Created',
  PartiallyProcessed = 'PartiallyProcessed',
  Processed = 'Processed',
  Manual = 'Manual',
  Processing = 'Processing',
  TechTrouble = 'TechTrouble',
  Arrested = 'Arrested'
}

export enum TransferType {
  Deposit = 'Deposit',
  InvoiceDeposit = 'InvoiceDeposit',
  ReferralReward = 'ReferralReward',
  Withdraw = 'Withdraw',
  PayoutWithdraw = 'PayoutWithdraw',
  Refund = 'Refund',
  Convert = 'Convert'
}

export type TransferWhereInput = {
  AND?: Maybe<Array<TransferWhereInput>>;
  OR?: Maybe<Array<TransferWhereInput>>;
  NOT?: Maybe<Array<TransferWhereInput>>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumTransferStatusFilter>;
  supportStatus?: Maybe<EnumTransferStatusSupportFilter>;
  type?: Maybe<EnumTransferTypeFilter>;
  invoiceId?: Maybe<StringNullableFilter>;
  asset?: Maybe<StringFilter>;
  amount?: Maybe<DecimalFilter>;
  processedAmount?: Maybe<DecimalNullableFilter>;
  fee?: Maybe<DecimalNullableFilter>;
  companyId?: Maybe<StringFilter>;
  paymentCode?: Maybe<StringFilter>;
  reason?: Maybe<StringNullableListFilter>;
  address?: Maybe<StringNullableFilter>;
  convertId?: Maybe<StringNullableFilter>;
  payoutOperationId?: Maybe<StringNullableFilter>;
  payload?: Maybe<JsonFilter>;
  isCreatedByApi?: Maybe<BoolFilter>;
  isHidden?: Maybe<BoolFilter>;
  creatorId?: Maybe<StringNullableFilter>;
  creatorComment?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  merchantId?: Maybe<StringFilter>;
  logicalTime?: Maybe<BigIntFilter>;
  Asset?: Maybe<AssetRelationFilter>;
  Invoice?: Maybe<InvoiceNullableRelationFilter>;
  PaymentMethod?: Maybe<PaymentMethodRelationFilter>;
  Company?: Maybe<CompanyRelationFilter>;
  Convert?: Maybe<ConvertNullableRelationFilter>;
  PayoutOperation?: Maybe<PayoutOperationNullableRelationFilter>;
  TransferStatusHistory?: Maybe<TransferStatusHistoryListRelationFilter>;
};

export enum TypeOfCounterpartyGroup {
  RateLimit = 'RateLimit',
  Withdraw = 'Withdraw',
  Deposit = 'Deposit',
  Invoice = 'Invoice',
  AutoConvert = 'AutoConvert',
  PayoutWithdraw = 'PayoutWithdraw',
  ReferralReward = 'ReferralReward'
}

export enum TypeOfSignature {
  Hmac = 'HMAC',
  Rsa = 'RSA'
}

export type UpdateCompanyInput = {
  avatar?: Maybe<Scalars['String']>;
  /** Reference/Payment currency */
  referenceCurrency?: Maybe<Scalars['String']>;
  referralPayoutCurrency?: Maybe<Scalars['String']>;
  supportInfo?: Maybe<Scalars['String']>;
  invoiceUnpaidPercent?: Maybe<Scalars['String']>;
};

export type UpdateCompanyNotificationSettingsInput = {
  entity: CompanyNotificationEntities;
  email: Scalars['Boolean'];
  push: Scalars['Boolean'];
};

export type UpdateCompanyRelationInput = {
  /** User Role */
  role: Scalars['String'];
};

export type UpdateOneCompanyAssetSettingsDataInput = {
  /** Precision can be no more than 2 characters. Correct example: 2.12; Incorrect: 2.1234 */
  invoiceCoefficient: Scalars['String'];
};

export type UpdateOneWithdrawDataInput = {
  comment: Scalars['String'];
};

export type UpdatePayoutDataInput = {
  title: Scalars['String'];
};

export type UpdatePayoutOperationDetailsDataInput = {
  amount?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type UpdatePayoutWhereInput = {
  payoutId: Scalars['String'];
};

export type UpdateProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Selected interface language */
  language?: Maybe<AvailableLanguages>;
  /** User's fullName */
  fullName?: Maybe<Scalars['String']>;
};

export type UpdateUniqueAssetWhereInput = {
  code: Scalars['String'];
};

export type UpdateUserNotificationSettingsInput = {
  entity: ProfileNotificationEntities;
  email: Scalars['Boolean'];
  push: Scalars['Boolean'];
};

export type UpsertOneCompanyAssetSettingsInput = {
  where: UpdateUniqueAssetWhereInput;
  data: UpdateOneCompanyAssetSettingsDataInput;
};

export type UserProfileInformationOutput = {
  id: Scalars['String'];
  sn: Scalars['Int'];
  username: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  fullName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identificationStatus: IdentificationStatus;
  merchantId: Scalars['String'];
  role: Scalars['String'];
  isSuspicious: Scalars['Boolean'];
  isKUNAEmployee: Scalars['Boolean'];
  isAgreePrivacyPolicy: Scalars['Boolean'];
  status: StatusOfUser;
  utm: Scalars['String'];
  affiliateId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  email: Scalars['String'];
  isEnabledOtp: Scalars['Boolean'];
};

export type UserProfileOutput = {
  id: Scalars['String'];
  sn: Scalars['Int'];
  username: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  fullName: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  identificationStatus: IdentificationStatus;
  merchantId: Scalars['String'];
  role: Scalars['String'];
  isSuspicious: Scalars['Boolean'];
  isKUNAEmployee: Scalars['Boolean'];
  isAgreePrivacyPolicy: Scalars['Boolean'];
  status: StatusOfUser;
  utm: Scalars['String'];
  affiliateId?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  logicalTime: Scalars['BigInt'];
  ConfirmationSetting?: Maybe<ConfirmationSetting>;
  NotificationSetting?: Maybe<NotificationSettingsOutput>;
  relatedCompanies?: Maybe<Array<UserProfileRelatedCompanies>>;
};

export type UserProfileRelatedCompanies = {
  id: Scalars['String'];
  title: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
};

export type ValidateCryptoAddressBlockListByPaymentMethodOutput = {
  isValid: Scalars['Boolean'];
};

export type ZenNullableRelationFilter = {
  is?: Maybe<ZenWhereInput>;
  isNot?: Maybe<ZenWhereInput>;
};

export enum ZenStatus {
  Empty = 'EMPTY',
  Create = 'CREATE',
  Declined = 'DECLINED',
  Processing = 'PROCESSING',
  Connected = 'CONNECTED',
  ChallengeCancelled = 'CHALLENGE_CANCELLED',
  IntegrationDeleted = 'INTEGRATION_DELETED'
}

export type ZenWhereInput = {
  AND?: Maybe<Array<ZenWhereInput>>;
  OR?: Maybe<Array<ZenWhereInput>>;
  NOT?: Maybe<Array<ZenWhereInput>>;
  id?: Maybe<StringFilter>;
  linkId?: Maybe<StringFilter>;
  phone?: Maybe<StringFilter>;
  accountId?: Maybe<StringNullableFilter>;
  reason?: Maybe<StringNullableFilter>;
  confirmationUrl?: Maybe<StringNullableFilter>;
  status?: Maybe<EnumZenStatusFilter>;
  userId?: Maybe<StringFilter>;
  Company?: Maybe<CompanyRelationFilter>;
};
