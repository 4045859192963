import {
  attach,
  combine,
  createEffect,
  createEvent,
  merge,
  sample,
} from 'effector';
import { not } from 'patronum';

import { listen } from '@kuna-pay/utils/effector';
import { atom, bridge } from '@kuna-pay/utils/misc';
import type { JWTCompanyRole } from '@kuna-pay/core/entities/session';
import { CompanyJWTModelFactory } from '@kuna-pay/core/entities/session';
import { ErrorMatcher } from '@kuna-pay/core/shared/api';
import { CrowdinInContextConfig } from '@kuna-pay/core/shared/i18n';

import { FreshDeskSDK } from '@kuna-pay/merchant/features/contact-support/fresh-desk';
import type { AvailableLanguages } from '@kuna-pay/merchant/generated/graphql';
import { StatusOfUser } from '@kuna-pay/merchant/generated/graphql';
import {
  $$analytics,
  $$googleAnalytics,
} from '@kuna-pay/merchant/shared/analytics';
import { $$api } from '@kuna-pay/merchant/shared/api';
import { freshDeskAuth } from '@kuna-pay/merchant/shared/api/generated/Common/request/freshDeskAuth';
import { $$i18n } from '@kuna-pay/merchant/shared/i18n';
import { $$monitoring } from '@kuna-pay/merchant/shared/monitoring';
import { $$router, routes } from '@kuna-pay/merchant/shared/router';

import { $$merchantQuery } from './queries/company.query';
import { $$meQuery } from './queries/me.query';
import { UpdateProfileLanguageUseCase } from './use-cases';

const $$session = atom(() => {
  const init = createEvent();

  const $$jwt = CompanyJWTModelFactory.create({
    $jwt: $$api.tokenService.$accessToken,
  });

  const updateProfileLanguageFx = createEffect({
    name: '$$session.updateProfileLanguageFx',
    handler: async (language: AvailableLanguages) => {
      const updateProfileLanguageUseCase = new UpdateProfileLanguageUseCase();

      await updateProfileLanguageUseCase.execute(language);

      await $$analytics.updateIdentity({ language });
    },
  });

  bridge(() => {
    const blockedStatuses = [
      StatusOfUser.Blocked,
      StatusOfUser.PermanentBlocked,
      StatusOfUser.Deleted,
    ];

    //TODO: Use $$jwt.$status instead
    //TODO: more this logic into listen handler
    const userStatusChangedToBlocked = sample({
      clock: combine(
        $$meQuery.$data,
        (maybeData) => maybeData?.status ?? null
      ).updates.filter({
        fn: (maybeStatus): maybeStatus is StatusOfUser => !!maybeStatus,
      }),

      filter: (status) => blockedStatuses.includes(status),
    });

    listen({
      clock: userStatusChangedToBlocked,
      handler: () => {
        // Need to clear merchant data but not logout
        $$merchantQuery.reset();

        $$router.redirect(routes.auth.blocked);
      },
    });
  });

  bridge(() => {
    sample({
      clock: not($$jwt.$accessTokenJwt),
      filter: Boolean,
      target: $$meQuery.reset,
    });

    sample({
      clock: not($$jwt.$$company.$isCompanyLogged),
      filter: Boolean,
      target: $$merchantQuery.reset,
    });
  });

  bridge(() => {
    listen({
      clock: merge([init, $$jwt.$accessTokenJwt.updates]),
      source: { id: $$jwt.$$user.$id, companyId: $$jwt.$$company.$id },
      handler: (_, { id, companyId }) => {
        void $$monitoring.identify(id);

        void $$analytics.identify(id);
        void $$googleAnalytics.identify(id);

        void $$monitoring.setTag({ key: 'companyId', value: companyId });
      },
    });
  });

  listen({
    clock: $$meQuery.finished.success,
    handler: ({ result: { firstName, lastName, fullName, email } }) => {
      let name = fullName;

      if (firstName && lastName) {
        name = `${firstName} ${lastName}`;
      } else {
        if (firstName) {
          name = firstName;
        }

        if (lastName) {
          name = lastName;
        }
      }

      FreshDeskSDK.identify({ name, email });

      const request = freshDeskAuth({ token: true });

      request({ name, email })
        .then(({ token }) => {
          FreshDeskSDK.authenticate({
            token,
            onRefresh: () => request({ name, email }),
          });
        })
        .catch(console.error);
    },
  });

  listen({
    name: '$$session.syncLanguageWithBackendOnLogin',
    clock: $$meQuery.finished.success,
    source: $$i18n.$instance,
    handler: ({ result }, i18n) => {
      if (!i18n.resolvedLanguage) {
        i18n.on('initialized', handleLanguageSync);
      } else {
        void handleLanguageSync();
      }

      async function handleLanguageSync() {
        const currentLanguageOnScreen = i18n.resolvedLanguage!;

        if (
          currentLanguageOnScreen === CrowdinInContextConfig.PSEUDO_LANGUAGE
        ) {
          return;
        }

        void $$analytics.updateIdentity({ language: result.language });

        if (currentLanguageOnScreen !== result.language) {
          i18n.changeLanguage(result.language);
        }

        i18n.off('initialized', handleLanguageSync);
      }
    },
  });

  listen({
    name: '$$session.onMerchantQueryFailedWithForbidden',
    clock: $$merchantQuery.finished.failure,
    handler: ({ error }) => {
      if (ErrorMatcher.createErrorMatcher('FORBIDDEN')(error)) {
        $$router.redirect(routes.merchant.list);
      }
    },
  });

  return {
    init,

    $$meQuery,
    $$merchantQuery,

    $$me: {
      $$query: $$meQuery,
      updateProfileLanguageFx,
    },

    $$jwt,
  };
});

const createCompanyOrOwnDataRequest = <T, R>(
  defaultMethod: (param: T) => R,

  config: Partial<Record<JWTCompanyRole, (param: T) => R>>
) =>
  attach({
    source: $$session.$$jwt.$$company.$role,

    effect: (role, params: T) => {
      const method = config[role as JWTCompanyRole] ?? defaultMethod;

      return method(params);
    },
  });

export { $$session, createCompanyOrOwnDataRequest };
