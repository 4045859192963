import {
  AuthenticateFreshDeskOutput,
  MutationFreshDeskAuthArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const freshDeskAuthRequest = (
  select: Select<AuthenticateFreshDeskOutput> | string
) => `mutation freshDeskAuth($name: String!, $email: String!) {
  data: freshDeskAuth(name: $name, email: $email) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'AuthenticateFreshDeskOutput')}`;
export const freshDeskAuth =
  (select: Select<AuthenticateFreshDeskOutput> | string) =>
  (args: MutationFreshDeskAuthArgs) =>
    query<AuthenticateFreshDeskOutput>(freshDeskAuthRequest(select), args);
