import { createGate, useUnit } from 'effector-react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { Button } from '@kuna-pay/ui/ui/button';

import { FreshDeskSDK } from '@kuna-pay/merchant/features/contact-support/fresh-desk';

import { ExceedLimitsOfIndividualPlanAlert } from './exceed-limits-of-individual-plan-alert';
import styles from './exceed-limit-alert.module.scss';

const ExceedLimitAlert = createCompoundComponent(
  (C) =>
    memo(() => {
      const { t } = useTranslation();
      const isGateMounted = useUnit(C.Gate.status);

      if (!isGateMounted) {
        return null;
      }

      return (
        <ExceedLimitsOfIndividualPlanAlert
          classes={{
            root: styles.root,
            content: styles.content,
            text: styles.text,
          }}
        >
          <Button
            variant='text'
            color='primary'
            size='sm'
            disablePaddingLeft
            disablePaddingTop
            disablePaddingBottom
            disablePaddingRight
            onClick={() => FreshDeskSDK.openTicketForm()}
          >
            {t('widgets.layout.exceed-limit-alert.action')}
          </Button>
        </ExceedLimitsOfIndividualPlanAlert>
      );
    }),
  {
    Gate: createGate(),

    useIsVisible: ExceedLimitsOfIndividualPlanAlert.useIsVisible,
  }
);

export { ExceedLimitAlert };
